import { increaseFrameVersion, KettleFrameId } from '../../../../../../domain/kettle/types/frame.ts';
import { AudioURL, KettleFileId } from '../../../../../../domain/kettle/types/shared.ts';
import { KettleRepository } from '../../kettle.repository.ts';
import { AudioSampleService } from './audioSample.service.ts';

export const updateAudioSampleTitleService = async (
  fileId: KettleFileId,
  frameId: KettleFrameId,
  audioURL: AudioURL,
  newTitle: string
) => {
  return KettleRepository.updateFramesInFile(fileId, (existingFrames) => {
    return (existingFrames ?? []).map((fr) =>
      fr.frameId === frameId
        ? {
            ...fr,
            samples: fr.samples.map((s) =>
              s.audioURL === audioURL ? AudioSampleService.sampleWithUpdatedTitle(s, newTitle) : s
            ),
            _version: increaseFrameVersion(fr._version),
          }
        : fr
    );
  });
};
