import { VoicePreset } from '../domain/kettle/types/shared';

const SAMPLE_TEXT_FOR_TESTING_VOICES = `Voice cloning is a technology that allows a computer to mimic or reproduce the voice of a human speaker. This can be done by using a process called "speech synthesis," which involves using a computer program to generate speech based on a set of predefined rules. The resulting speech sounds similar to the original speaker, but it is not an exact copy of their voice. Voice cloning technology has many potential applications, such as creating personal assistants, improving access to information for people with disabilities, and automating customer service tasks. However, it also raises concerns about privacy and the potential for abuse, so it is important to use this technology responsibly.`;
const DEFAULT_VOICE =
  's3://mockingbird-prod/larry_vo_narrative_4bd5c1bd-f662-4a38-b5b9-76563f7b92ec/voices/speaker/manifest.json';
const DEFAULT_PRESET = 'balanced' as VoicePreset;
const DEFAULT_SPEED = '1.0';
const VALID_SPEED_REGEX = /^\d\.\d$/;
const DEFAULT_SAMPLES_COUNT = 1;
const PREVIEWS_GENERATION_TIMEOUT_IN_MS = 5 * 60 * 1000; // 5 minutes

export const PEREGRINE_CONSTANTS = {
  SAMPLE_TEXT_FOR_TESTING_VOICES,
  DEFAULT_VOICE,
  DEFAULT_PRESET,
  DEFAULT_SPEED,
  VALID_SPEED_REGEX,
  DEFAULT_SAMPLES_COUNT,
  PREVIEWS_GENERATION_TIMEOUT_IN_MS,
};
