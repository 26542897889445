import { AudioOptions } from '../../../../../domain/kettle/types/audio.ts';
import { increaseFrameVersion, KettleFrameId } from '../../../../../domain/kettle/types/frame.ts';
import { KettleFileId } from '../../../../../domain/kettle/types/shared.ts';
import { KettleRepository } from '../kettle.repository.ts';

enum ChangeOptionsType {
  ALL_PARAGRAPHS = 'ALL_PARAGRAPHS',
  ACTIVE_PARAGRAPH = 'ACTIVE_PARAGRAPH',
  REPLACE_PREVIOUS_INSTANCES = 'REPLACE_PREVIOUS_INSTANCES',
}

export const updateFrameOptionsService = async (
  fileId: KettleFileId,
  frameId: KettleFrameId,
  newOptions: Partial<AudioOptions>,
  changeOptionsType: ChangeOptionsType,
  previousOptions: Pick<AudioOptions, 'voice'>
) => {
  return KettleRepository.updateFramesInFile(fileId, (frames) => {
    switch (changeOptionsType) {
      case ChangeOptionsType.ACTIVE_PARAGRAPH:
        return (frames ?? []).map((frame) => {
          if (frame.frameId !== frameId) return frame;
          return {
            ...frame,
            options: { ...frame.options, ...newOptions },
            _version: increaseFrameVersion(frame._version),
          };
        });

      case ChangeOptionsType.ALL_PARAGRAPHS:
        return (frames ?? []).map((frame) => ({
          ...frame,
          options: { ...frame.options, ...newOptions },
          _version: increaseFrameVersion(frame._version),
        }));

      case ChangeOptionsType.REPLACE_PREVIOUS_INSTANCES:
        return (frames ?? []).map((frame) => {
          if (!(frame.options.voice === previousOptions.voice || frame.frameId === frameId)) return frame;
          return {
            ...frame,
            options: { ...frame.options, ...newOptions },
            _version: increaseFrameVersion(frame._version),
          };
        });
    }
  });
};
