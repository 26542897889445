import { UserId } from '../../../../domain/auth/User.ts';
import { KettleFileId } from '../../../../domain/kettle/types/shared.ts';
import { KettleMetadataRepository } from './kettleMetadata.repository.ts';
import { RecentFilesRepository } from '../recent-files/RecentFilesRepository.ts';

export const updateEditorTitleService = async (
  userId: UserId,
  fileId: KettleFileId,
  newTitle: string,
  isAutogenerated: boolean
) => {
  await KettleMetadataRepository.updateFileMetadata(fileId, {
    title: newTitle,
    isTitleAutogenerated: isAutogenerated,
  });
  await RecentFilesRepository.updateRecentFile(userId, fileId, {
    title: newTitle,
  });
};
