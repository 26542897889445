import React, { FC, useEffect, useState } from 'react';
import { AnnouncementsJournalSteps } from './AnnouncementsJournalSteps';
import { Announcement, ANNOUNCEMENTS } from './announcements';
import { Modal } from '../Modal.tsx';
import { isUserOnboarded, useUser } from '../../contexts/user.context.tsx';
import { updateLastNewsSeenDate } from '../../API/user.requests.ts';
import { errorsTracker } from '../../infra/errors/errorsTracker.ts';
import { FeatureFlags, useFeatureFlags } from '../../hooks/useFeatureFlags.ts';
import { User } from '../../domain/auth/User.ts';

export const AnnouncementsJournal: FC = () => {
  const { user } = useUser();
  const announcements = useAnnouncements();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (announcements) setVisible(announcements.length > 0);
  }, [announcements]);

  const close = async () => {
    setVisible(false);
    await markNewsAsSeen();
  };

  const setOpen = async (open: boolean | ((prevState: boolean) => boolean)) => {
    const result = typeof open === 'function' ? open(visible) : open;
    setVisible(result);
    if (!open) await markNewsAsSeen();
  };

  const markNewsAsSeen = async () => {
    try {
      if (!user) return;
      await updateLastNewsSeenDate(user.id, new Date());
    } catch (error) {
      errorsTracker.report('warning', error, 'Failed to mark news as seen');
    }
  };

  if (!visible || !announcements) return null;

  return (
    <Modal open={visible} setOpen={setOpen}>
      <AnnouncementsJournalSteps announcements={announcements} onFinish={close} />
    </Modal>
  );
};

function useAnnouncements(): Array<Announcement> | null {
  const [announcements, setAnnouncements] = useState<Announcement[] | null>(null);
  const { featureFlags, featureFlagsLoading } = useFeatureFlags();
  const { user } = useUser();

  useEffect(() => {
    // Ensure that we determine announcements to show only once, before feature flags and user are loaded
    if (!announcements && user && !featureFlagsLoading) {
      setAnnouncements(
        ANNOUNCEMENTS.filter((announcement) => shouldShowAnnouncementToUser(announcement, user, featureFlags))
      );
    }
  }, [user, featureFlags, featureFlagsLoading, announcements]);

  return announcements;
}

function shouldShowAnnouncementToUser(announcement: Announcement, user: User, featureFlags: FeatureFlags) {
  return (
    isAnnouncementFeatureFlagEnabled(announcement, featureFlags) &&
    isAnnouncementPublished(announcement) &&
    isUserOnboarded(user) &&
    isUserCreationOlderThanAnnouncement(announcement, user) &&
    hasUserNotSeenAnnouncement(announcement, user)
  );
}

function isAnnouncementFeatureFlagEnabled(announcement: Announcement, featureFlags: FeatureFlags) {
  return !announcement.featureFlag || featureFlags[announcement.featureFlag];
}

function isAnnouncementPublished(announcement: Announcement) {
  return new Date() >= new Date(announcement.publishedAt);
}

function isUserCreationOlderThanAnnouncement(announcement: Announcement, user: User) {
  return new Date(user?.created_at ?? '') < new Date(announcement.publishedAt);
}

function hasUserNotSeenAnnouncement(announcement: Announcement, user: User) {
  const lastNewsSeenDate = user.singleViewItems?.lastNewsSeenDate;
  return !lastNewsSeenDate || new Date(lastNewsSeenDate) < new Date(announcement.publishedAt);
}
