import { ref, set, runTransaction, update, remove } from 'firebase/database';
import { get } from 'firebase/database';
import { databaseV2 } from '../../../../utils/firebase.ts';
import { UserId } from '../../../../domain/auth/User.ts';
import { RecentFile, RecentFilesNode } from '../../../../domain/kettle/RecentFile.ts';
import { KettleFileId } from '../../../../domain/kettle/types/shared.ts';

const DATABASE_V2_RECENT_FILES_KEY = 'recentFiles';

export const RecentFilesRepository = {
  findRecentFileslastCleanUpDate: async (userId: UserId) =>
    (await get(ref(databaseV2, `${DATABASE_V2_RECENT_FILES_KEY}/${userId}/lastCleanUpDate`))).val(),
  setRecentFilesForUser: async (userId: UserId, files: RecentFile[]) =>
    set(ref(databaseV2, `${DATABASE_V2_RECENT_FILES_KEY}/${userId}`), {
      lastCleanUpDate: Date.now(),
      files: Object.fromEntries(files.map((file) => [file.id, file])),
    }),
  updateRecentFiles: async (userId: UserId, updateFunction: (recentFiles: RecentFilesNode) => RecentFilesNode) =>
    runTransaction(ref(databaseV2, `${DATABASE_V2_RECENT_FILES_KEY}/${userId}`), (currentData) => {
      return updateFunction(currentData);
    }),
  upsertRecentFile: async (userId: UserId, projectId: KettleFileId, title: string | null) =>
    set(
      ref(databaseV2, `${DATABASE_V2_RECENT_FILES_KEY}/${userId}/files/${projectId}`),
      createRecentFilesFileEntry({
        id: projectId,
        title: title ?? undefined,
      })
    ),
  updateRecentFile: async (userId: UserId, projectId: KettleFileId, docUpdate: Partial<RecentFile>) =>
    update(ref(databaseV2, `${DATABASE_V2_RECENT_FILES_KEY}/${userId}/files/${projectId}`), {
      ...docUpdate,
      modifiedDate: Date.now(),
    }),
  removeRecentFile: async (userId: UserId, fileId: KettleFileId) =>
    remove(ref(databaseV2, `${DATABASE_V2_RECENT_FILES_KEY}/${userId}/files/${fileId}`)),
};

export function createRecentFilesFileEntry({
  id,
  title,
  modifiedDate = Date.now(),
}: {
  id: KettleFileId;
  title?: string;
  modifiedDate?: number;
}): RecentFile {
  return {
    id,
    title: title ?? null,
    isStudio: true, // right now we only record studio files
    modifiedDate,
  };
}
