import { useDeleteFiles } from '../../hooks/files.hooks.ts';
import { useEffect, useState } from 'react';
import { Button } from '../../components/Button.tsx';
import { Dialog } from '@headlessui/react';
import { Modal } from '../../components/Modal.tsx';
import { KettleFileId } from '../../domain/kettle/types/shared.ts';

export function DeleteFilesButton({
  filesIds,
  onDelete,
}: {
  filesIds: string[];
  onDelete: (deletedFilesIds: string[]) => void;
}) {
  const { mutate: deleteFiles, status, reset } = useDeleteFiles();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      onDelete(filesIds);
      setModalOpen(false);
      reset();
    }
  }, [status, reset, onDelete, filesIds]);

  if (filesIds.length === 0) return null;
  return (
    <>
      <Button intent="error" onClick={() => setModalOpen(true)}>
        Delete Files
      </Button>
      <Modal maxWidth="500px" open={modalOpen} setOpen={setModalOpen}>
        <div className="p-2">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6">
            Delete file(s)
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm">
              Are you sure you want to delete the selected file? All of your data will be permanently removed. This
              action cannot be undone.
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-3 px-4 py-3">
          <Button format="outlined" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button intent="error" onClick={() => deleteFiles(filesIds as KettleFileId[])} loading={status === 'loading'}>
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
}
