import { z } from 'zod';

export const paragraphTextSchema = z.string().brand('__ParagraphText');
export type ParagraphText = z.infer<typeof paragraphTextSchema>;

export const VOICE_SPEEDS = [1.5, 1.4, 1.3, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7, 0.6, 0.5] as const;
export const voiceSpeedSchema = z
  .enum(['1.5', '1.4', '1.3', '1.2', '1.1', '1.0', '0.9', '0.8', '0.7', '0.6', '0.5'])
  .transform(Number);
export type VoiceSpeed = z.infer<typeof voiceSpeedSchema>;

export const emotionIdSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
  z.literal(7),
  z.literal(8),
  z.literal(9),
  z.literal(10),
  z.literal(11),
  z.literal(12),
  z.literal(13),
]);
export type EmotionId = z.infer<typeof emotionIdSchema>;

export const audioOptionsSchema = z.object({
  text: paragraphTextSchema,
  voice: z.string(),
  speed: voiceSpeedSchema,
  emotion: emotionIdSchema.nullable(),
  style: z.string().nullable(),
  temperature: z.number().nullable(),
  voiceGuidance: z.number().nullable(),
  styleGuidance: z.number().nullable(),
  language: z.string().nullable().optional(),
});
export type AudioOptions = z.infer<typeof audioOptionsSchema>;
