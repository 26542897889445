import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { Modal } from '../Modal';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { CONFIG } from '../../config';
import {
  cycle,
  CycleType,
  getActivePackage,
  getDisplayPrice,
  getDisplayPriceBeforeDiscount,
  PlanT,
  studioPlans,
} from '../../utils/plans.helpers';
import { useNavigate } from 'react-router-dom';
import { RadioList } from '../RadioList';
import { billingCycles } from '../../pages/Pricing/PricingPage';
import { usePostHogTracking } from '../../hooks/usePostHogTracking';
import { useUser } from '../../contexts/user.context';
import { isUserOnCreatorPlan } from '@playht/component-kettle-editor';
import { motion, AnimatePresence } from 'framer-motion';
import clsx from 'clsx';

const PLAN_SLICE_START = 1; // creator plan
const PLAN_SLICE_END = 3; // unlimited plan

type PlanFeatureProps = {
  name: string;
  available: boolean;
  info?: string;
};

const PlanFeature = ({ name, available, info }: PlanFeatureProps) => (
  <div className="flex items-center gap-2 group relative" title={info}>
    {available ? (
      <svg
        className="w-5 h-5 text-green-500 flex-shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      </svg>
    ) : (
      <svg
        className="w-5 h-5 text-red-500 flex-shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    )}
    <span className="text-sm">{name}</span>
  </div>
);

const PlanButton = ({ plan, isSelected, onClick }: { plan: PlanT; isSelected: boolean; onClick: () => void }) => (
  <button
    className={clsx(
      'px-4 py-2 rounded-md flex-1 min-w-fit font-semibold transition-colors duration-200',
      isSelected ? 'bg-gray-700' : 'hover:bg-gray-700'
    )}
    onClick={onClick}
    aria-pressed={isSelected}
  >
    {plan.name}
  </button>
);

const PriceDisplay = ({ plan }: { plan: PlanT }) => (
  <div className="flex items-start justify-between mb-4">
    <div>
      <div className="flex items-center gap-2">
        {getDisplayPriceBeforeDiscount(plan) && (
          <span className="text-3xl font-bold line-through text-gray-400">${getDisplayPriceBeforeDiscount(plan)}</span>
        )}
        <span className="text-3xl font-bold">${getDisplayPrice(plan)}</span>
        <span className="text-sm text-gray-400">/ month</span>
        {plan.isOnDiscount && plan.priceBeforeDiscount && (
          <motion.span
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="border border-primary-green-600 rounded-md text-primary-green-600 font-semibold text-xs px-2 py-1"
          >
            {Math.round(((plan.priceBeforeDiscount - plan.price) / plan.priceBeforeDiscount) * 100)}% OFF First{' '}
            {plan.cycle === cycle.ANNUALLY ? 'Year' : 'Month'}
          </motion.span>
        )}
      </div>
      <p className="text-sm text-gray-400">
        USD ${plan.price} billed {plan.cycle === cycle.ANNUALLY ? 'annually' : 'monthly'}
      </p>
    </div>
  </div>
);

export function UpgradeModal({
  open,
  setOpen,
  modalTitle = 'Upgrade Your Plan',
  modalDescription = 'To unlock all voice models, upgrade your plan.',
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  modalTitle?: string;
  modalDescription?: string;
}) {
  const { user } = useUser();
  const activePlanId = user ? getActivePackage(user)?.id ?? null : null;
  const userIsOnCreatorPlan = isUserOnCreatorPlan(activePlanId);

  return (
    <Modal open={open} setOpen={setOpen}>
      <UpgradeModalContent
        setOpen={setOpen}
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        userIsOnCreatorPlan={userIsOnCreatorPlan}
      />
    </Modal>
  );
}

function UpgradeModalContent({
  setOpen,
  modalTitle,
  modalDescription,
  userIsOnCreatorPlan,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  modalDescription: string;
  userIsOnCreatorPlan: boolean;
}) {
  const navigate = useNavigate();
  const { posthogCaptureEvent } = usePostHogTracking();
  const [isLoading, setIsLoading] = useState(false);

  const [billingCycle, setBillingCycle] = useState(userIsOnCreatorPlan ? billingCycles[1] : billingCycles[0]);

  const plans = useMemo(() => {
    return studioPlans
      .filter((plan) => plan.cycle === billingCycle.id)
      .slice(userIsOnCreatorPlan ? PLAN_SLICE_START + 1 : PLAN_SLICE_START, PLAN_SLICE_END);
  }, [billingCycle.id, userIsOnCreatorPlan]);

  const [currentPlanId, setCurrentPlanId] = useState(plans[0]?.key);
  const currentPlan = useMemo(() => studioPlans.find((plan) => plan.key === currentPlanId), [currentPlanId]);

  const handleChangeCycle = useCallback(
    (newCycle: { id: CycleType; title: string; disc: string }) => {
      const currentPlanName = currentPlan?.name;
      setBillingCycle(newCycle);
      const newPlan = studioPlans.find((plan) => plan.cycle === newCycle.id && plan.name === currentPlanName);
      if (newPlan) {
        setCurrentPlanId(newPlan.key);
      }
    },
    [currentPlan?.name]
  );

  const handleGetPlan = useCallback(async () => {
    if (!currentPlan) return;

    setIsLoading(true);
    try {
      posthogCaptureEvent('upgrade_plan_modal_get_plan_clicked', {
        plan: currentPlan.name,
      });
      navigate(`/studio/checkout/?plan=${currentPlanId}`);
      setOpen(false);
    } catch (error) {
      console.error('Failed to process plan selection:', error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPlan, currentPlanId, navigate, posthogCaptureEvent, setOpen]);

  if (!currentPlan) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="bg-black text-white p-6 rounded-md max-w-xl w-full lg:max-w-5xl"
    >
      <div className="flex justify-between items-center mb-6">
        <span className="h-6 w-6" />
        <h2 className="text-xl font-semibold text-center">{modalTitle}</h2>
        <button
          onClick={() => setOpen(false)}
          className="text-gray-400 hover:text-white transition-colors duration-200"
          aria-label="Close modal"
        >
          <XMarkIcon className="w-6 h-6" />
        </button>
      </div>

      <p className="mb-6 text-base text-center flex flex-col gap-1">
        <span>{userIsOnCreatorPlan ? 'Upgrade to unlock unlimited regenerations.' : modalDescription}</span>
        <span className="text-gray-400">
          {userIsOnCreatorPlan ? 'You are currently on the creator plan.' : 'You are currently on the free plan.'}
        </span>
      </p>

      <div className="flex gap-2 bg-gray-800 p-1 rounded-lg mb-6">
        <AnimatePresence mode="wait">
          {plans.map((plan) => (
            <PlanButton
              key={plan.key}
              plan={plan}
              isSelected={plan.key === currentPlanId}
              onClick={() => setCurrentPlanId(plan.key)}
            />
          ))}
        </AnimatePresence>
      </div>

      <div className="bg-gray-900 rounded-lg p-6 mb-6">
        <PriceDisplay plan={currentPlan} />

        <div className="space-y-2">
          {currentPlan.features.map((feature) => (
            <PlanFeature key={feature.name} name={feature.name} available={feature.available} info={feature.info} />
          ))}
        </div>

        <div className="mt-6">
          <RadioList options={billingCycles} selectedOption={billingCycle} setSelectedOption={handleChangeCycle} />
        </div>

        <div className="mt-6 text-center text-sm text-gray-400">
          <p>
            Compare plans and options on our{' '}
            <a
              href={CONFIG.playWebsiteBaseUrl + '/pricing/?planType=studio'}
              className="text-green-500 hover:text-green-400 transition-colors duration-200 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              pricing page ↗
            </a>
          </p>
        </div>
      </div>

      <button
        onClick={handleGetPlan}
        disabled={isLoading}
        className={clsx(
          'w-full py-4 bg-green-400 text-black font-semibold text-lg rounded-lg transition-all duration-200',
          'hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-black',
          'disabled:opacity-50 disabled:cursor-not-allowed'
        )}
      >
        {isLoading ? 'Processing...' : 'Get Plan'}
      </button>
    </motion.div>
  );
}
