import { useState } from 'react';
import { isUserOnboarded, useUser } from '../contexts/user.context.tsx';
import { OnboardingModal } from './OnboardingModal';
import { SpinnerFullScreen } from './SpinnerFullScreen.tsx';
import { useAuth } from '../contexts/auth.context.tsx';

export function RequireOnboarding({ children }: { children: JSX.Element }) {
  const { user } = useUser();
  const { currentUser } = useAuth();

  const [showOnboarding, setShowOnboarding] = useState(false);

  if (!user) return <SpinnerFullScreen label="Loading preferences..." />;

  if (!isUserOnboarded(user) && !showOnboarding && currentUser?.emailVerified) {
    setShowOnboarding(true);
  }

  return (
    <>
      {children}
      <OnboardingModal open={showOnboarding} setOpen={setShowOnboarding} />
    </>
  );
}
