import { increaseFrameVersion } from '../../../../../../domain/kettle/types/frame.ts';
import { KettleFrameId } from '../../../../../../domain/kettle/types/frame.ts';
import { AudioURL, KettleFileId } from '../../../../../../domain/kettle/types/shared.ts';
import { KettleRepository } from '../../kettle.repository.ts';
import { inferSelectedSample } from '../../inferSelectedSample.ts';

export const removeAudioSampleService = (fileId: KettleFileId, frameId: KettleFrameId, audioURL: AudioURL) => {
  return KettleRepository.updateFramesInFile(fileId, (existingFrames) => {
    return (existingFrames ?? []).map((fr) => {
      if (fr.frameId !== frameId) return fr;

      const filteredSamples = fr.samples.filter((s) => s.audioURL !== audioURL);
      return {
        ...fr,
        samples: filteredSamples,
        selectedSample: inferSelectedSample(fr.selectedSample, filteredSamples),
        _version: increaseFrameVersion(fr._version),
      };
    });
  });
};
