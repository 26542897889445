import { create } from 'zustand';
import { KettleFileMetadata } from '../domain/kettle/types/file.ts';
import { UserId } from '../domain/auth/User.ts';
import { KettleMetadataRepository } from '../pages/KettlePage/db/studio-file-metadata/kettleMetadata.repository.ts';

type FileStore = FileStoreState & FileStoreActions;

interface FileStoreState {
  userId: UserId | null;
  files: KettleFileMetadata[] | null;
  listening: boolean;
  unsubscribe: (() => void) | null;
}

interface FileStoreActions {
  listenFiles: (userId: UserId) => void;
}

export const useFilesStore = create<FileStore>((set, get) => ({
  userId: null,
  files: null,
  listening: false,
  listenersCount: 0,
  unsubscribe: null,

  listenFiles: (userId: UserId) => {
    const { userId: currentUserId, listening } = get();
    if (listening && userId === currentUserId) return;
    if (userId !== currentUserId) {
      get().unsubscribe?.();
    }
    const unsubscribeFirebase = KettleMetadataRepository.listenUserFilesMetadata(userId, (snapshot) => {
      const result = snapshot.docs.map((doc) => doc.data() as KettleFileMetadata);
      const files = (result ?? []).filter((f) => f.isStudio).sort((a, b) => b.modifiedDate - a.modifiedDate);
      set({ files });
    });
    set({
      listening: true,
      unsubscribe: () => {
        unsubscribeFirebase();
        set(() => ({ listening: false, unsubscribe: null, files: null }));
      },
      userId,
    });
  },
}));
