import { KettleFrameId } from '../../../../../domain/kettle/types/frame.ts';
import { Version } from '../../../../../domain/kettle/types/shared.ts';
import { KettleFileId } from '../../../../../domain/kettle/types/shared.ts';
import { KettleRepository } from '../kettle.repository.ts';

export const updateFramesDelaysService = async (
  fileId: KettleFileId,
  frameIdsWithNewDelays: Array<{ frameId: KettleFrameId; newDelay: number; newFrameVersion: Version }>
) => {
  return KettleRepository.updateFramesInFile(fileId, (frames) => {
    return (frames ?? []).map((frame) => {
      const fwd = frameIdsWithNewDelays.find((fo) => fo.frameId === frame.frameId);
      return fwd ? { ...frame, delay: fwd.newDelay, _version: fwd.newFrameVersion } : frame;
    });
  });
};
