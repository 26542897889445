import { KettleAudioSample } from '../../../../domain/kettle/types/sample.ts';
import { AudioURL } from '../../../../domain/kettle/types/shared.ts';
import { AudioSampleService } from './frame/audio-sample/audioSample.service.ts';

export const inferSelectedSample = (selectedSample: AudioURL | null, updatedSamples: KettleAudioSample[]) => {
  if (!updatedSamples || updatedSamples.length === 0) return null;
  // selectedSample exists
  if (selectedSample && updatedSamples.some((s) => s.audioURL === selectedSample)) return selectedSample;
  // no selected sample, we pick the first one
  return samplesSortedByTimestamp(updatedSamples)[0].audioURL ?? null;
};

// const inferSelectedSampleAfterSampleJustCompleted = (selectedSample, updatedSamples, completedAudioURLs) => {
//   const firstNewlyCompletedSampleURL = completedAudioURLs[0];
//   const newSelectedSampleURL = inferSelectedSample(selectedSample, updatedSamples);

//   const newSelectedSample = updatedSamples.find((s) => s.audioURL === newSelectedSampleURL);
//   const firstNewlyCompletedSample = updatedSamples.find((s) => s.audioURL === firstNewlyCompletedSampleURL);

//   if (!firstNewlyCompletedSample) {
//     return newSelectedSampleURL;
//   }

//   if (newSelectedSample.requestGenerationTime < firstNewlyCompletedSample.requestGenerationTime) {
//     return firstNewlyCompletedSampleURL;
//   }
//   return newSelectedSampleURL;
// };

/**
 * Samples generated later (greater requestGenerationTime) should come first;
 * Samples generated in the same batch have the same requestGenerationTime, so we then sort by URL
 * @param {AudioSample[]} samples
 * @returns {AudioSample[]}
 */
const samplesSortedByTimestamp = (samples: KettleAudioSample[]) =>
  [...samples].map(AudioSampleService.normalizeAudioSample).sort((s1, s2) => {
    // Note that samples from the same request have the same requestGenerationTime
    if (
      s1.requestGenerationTime === s2.requestGenerationTime ||
      (!s1.requestGenerationTime && !s2.requestGenerationTime)
    )
      return s1.title.localeCompare(s2.title);
    if (!s1.requestGenerationTime) return 1;
    if (!s2.requestGenerationTime) return -1;
    return s2.requestGenerationTime.localeCompare(s1.requestGenerationTime);
  });
