import { useUser } from '../contexts/user.context';
import { User } from '../domain/auth/User';
import { getActivePackage } from '../utils/plans.helpers';

export function useIsPaidUser() {
  const { user, loading: userLoading } = useUser();

  if (!user || userLoading) return false;

  return checkIsPaidUser(user);
}

export function checkIsPaidUser(user: User): boolean {
  const activePackage = getActivePackage(user);
  const hasActivePackage = !!activePackage;
  const isTeamMember = user.isTeamMember;
  const isPaidUser = hasActivePackage || user.is_pro || isTeamMember ? true : false;
  return isPaidUser;
}
