import { ArrowTopRightOnSquareIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ALLOWED_MODEL_IDS_FOR_FREE_USERS, Model } from '../../constants/model';
import { useIsPaidUser } from '../../hooks/useIsPaidUser';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { Dispatch, SetStateAction } from 'react';

export const ListItem = ({
  onClick,
  model,
  selectedModel,
  setOpenUpgradeModal,
  closeMenu,
}: {
  onClick: (model: Model) => void;
  model: Model;
  selectedModel: Model;
  setOpenUpgradeModal: Dispatch<SetStateAction<boolean>>;
  closeMenu?: () => void;
}) => {
  const isPaidUser = useIsPaidUser();
  const isAllowedModel = isPaidUser || ALLOWED_MODEL_IDS_FOR_FREE_USERS.includes(model.id);

  return (
    <button
      className="p-4 flex gap-1 items-center hover:bg-[#141414] duration-200 ease-out rounded-2xl"
      onClick={() => {
        if (!isAllowedModel) {
          setOpenUpgradeModal(true);
          closeMenu?.();
          return;
        }
        onClick(model);
      }}
    >
      <span className="flex flex-col gap-1">
        <span className="flex items-center gap-2">
          <span
            className={clsx(
              'text-[12px] leading-5 font-semibold  duration-200 ease-out',
              model.id === selectedModel.id ? 'text-primary-green-500' : 'text-white'
            )}
          >
            {model.title}
          </span>
          {model.isNew ? (
            <span className="px-[4px] py-[2px] text-[12px] leading-4 font-medium text-black bg-primary-green-600 rounded">
              New
            </span>
          ) : null}
          <span className="px-[3px] py-[1px] text-[12px] leading-4 font-medium text-white border border-[rgba(255,255,255,0.1)] bg-gray-800 rounded">
            {model.label}
          </span>
        </span>
        <p
          className={clsx(
            'text-left text-[12px] leading-[1.3] font-normal text-white/80  duration-200 ease-out',
            model.id === selectedModel.id && 'text-primary-green-500/80'
          )}
        >
          {model.description}
        </p>
      </span>
      <span className="min-w-[20px]">
        {model.id === selectedModel.id ? (
          <>
            <CheckCircleIcon className="w-[15px] h-[15px] text-primary-green-500" />
          </>
        ) : model.id === 'Standard' ? (
          <>
            <ArrowTopRightOnSquareIcon className="w-[15px] h-[15px] text-white" />
          </>
        ) : !isAllowedModel ? (
          <>
            <LockClosedIcon className="w-[15px] h-[15px] text-white" />
          </>
        ) : null}
      </span>
    </button>
  );
};
