import { KettleAudioSample } from '../../../../../../domain/kettle/types/sample.ts';

// if there are more than one sample with the same audioURL, it just keeps the first
export function getUniqueAudioSamples(samples: Array<KettleAudioSample>) {
  const previousURLs = new Set();
  return samples.filter((sample) => {
    if (!previousURLs.has(sample.audioURL)) {
      previousURLs.add(sample.audioURL);
      return true;
    }
    return false;
  });
}
