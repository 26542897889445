import { useUser } from '../contexts/user.context.tsx';
import { useFirebaseListener } from './firebase.hooks.ts';
import { useEffect } from 'react';
import { useAuth } from '../contexts/auth.context.tsx';
import { NUMBER_OF_RECENT_FILES, RecentFile, RecentFileSchema, RecentFilesNode } from '../domain/kettle/RecentFile.ts';
import { userIdSchema } from '../domain/auth/User.ts';
import { RecentFilesService } from '../pages/KettlePage/db/recent-files/RecentFilesService.ts';

// Notice this is a global state, not a component state. The intention is to
// never dispatch the request more than once, even if the hook is used in
// multiple components.
let refreshRequested = false;

export function useRecentFiles() {
  const { currentUser } = useAuth();
  const { user } = useUser();
  const [recentFilesNode, state] = useFirebaseListener<{ exists: boolean; files: Array<RecentFile> }, RecentFilesNode>(
    `recentFiles/${user?.id}`,
    {
      transform(recentFilesFB) {
        return {
          exists: recentFilesFB !== null,
          files: Object.entries(recentFilesFB?.files ?? {})
            .map(([, rf]) => RecentFileSchema.safeParse(rf))
            .filter((result): result is { success: true; data: RecentFile } => result.success)
            .map(({ data }) => ({ ...data, title: data.title || null }))
            .sort((a, b) => b.modifiedDate - a.modifiedDate)
            .slice(0, NUMBER_OF_RECENT_FILES),
        };
      },
      databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_V2_URL,
    }
  );

  useEffect(() => {
    if (state === 'LISTENING' && !recentFilesNode?.exists && !refreshRequested) {
      refreshRequested = true;
      if (currentUser) {
        RecentFilesService.refreshRecentFiles(userIdSchema.parse(currentUser.uid));
      }
    }
  }, [currentUser, recentFilesNode, state]);

  if (!recentFilesNode?.exists) {
    return null;
  }
  return recentFilesNode.files;
}
