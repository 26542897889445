import { Dispatch, SetStateAction } from 'react';
import { OldModal } from '../../components/OldModal';
import StripeForm from '../../components/PaymentView/StripeForm';
import { addPaymentMethod } from '../../API/billing.requests';
import { User as AuthUser } from '@firebase/auth';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import { User } from '../../domain/auth/User.ts';

function AddNewPaymentMethodModal({
  visible,
  setVisible,
  onSuccess,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
}) {
  return (
    <OldModal open={visible} setOpen={setVisible} className="min-w-fit lg:min-w-[600px]">
      <StripeForm onSuccess={onSuccess} payButtonLabel="Add" doRequest={doRequest} />
    </OldModal>
  );
}

async function doRequest(authUser: AuthUser, user: User, stripe: Stripe, customerId: string, card: StripeCardElement) {
  if (!authUser) {
    throw new Error('User not authorized');
  }

  const idToken = await authUser?.getIdToken();

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card,
  });

  console.log('Stripe paymentMethod', error, paymentMethod);
  if (error) {
    throw error;
  }

  if (paymentMethod?.id == null) {
    throw new Error('No payment method id in response');
  }

  await addPaymentMethod(idToken, paymentMethod.id);
}

export { AddNewPaymentMethodModal };
