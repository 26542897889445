import { usePostHog } from 'posthog-js/react';
import { CONFIG } from '../config';
import { useAuth } from '../contexts/auth.context';

export function usePostHogTracking() {
  const posthog = usePostHog();
  const auth = useAuth();

  const posthogCaptureEvent = (event: string, metadata?: Record<string, unknown>) => {
    if (!CONFIG.isProduction || !posthog) return;
    try {
      posthog?.capture(event, { ...metadata, userId: auth.currentUser?.uid ?? 'unknown' });
    } catch (error) {
      console.error('Error capturing event:', event, metadata, error);
    }
  };

  return {
    posthogCaptureEvent,
  };
}
