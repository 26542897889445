import { UserNotificationType, UserResponse } from '@playht/component-kettle-editor';

export async function notifyUser(
  type: UserNotificationType,
  message: string,
  retryFn?: () => void
): Promise<UserResponse> {
  const displayMessage = `${type.toUpperCase()}\n\n${message}`;
  if (retryFn) {
    if (confirm(`${displayMessage}\n\nClick OK to retry, Cancel to dismiss.`)) {
      setTimeout(retryFn);
      return UserResponse.RETRIED;
    }
  } else {
    alert(displayMessage);
    console.log(displayMessage);
  }
  return UserResponse.DISMISSED;
}
