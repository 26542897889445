import { UserId } from '../../../../domain/auth/User.ts';
import { KettleFileId } from '../../../../domain/kettle/types/shared.ts';
import {
  CLEANUP_DELAY,
  NUMBER_OF_RECENT_FILES,
  RecentFile,
  RecentFileSchema,
  RecentFilesNode,
} from '../../../../domain/kettle/RecentFile.ts';
import { KettleMetadataRepository } from '../studio-file-metadata/kettleMetadata.repository.ts';
import { createRecentFilesFileEntry, RecentFilesRepository } from './RecentFilesRepository.ts';

export const RecentFilesService = {
  updateRecentFiles: async (userId: UserId, projectId: KettleFileId, title: string | null) => {
    await prepopulateOrCleanUpRecentFiles(userId);
    await RecentFilesRepository.upsertRecentFile(userId, projectId, title);
  },
  refreshRecentFiles: async (userId: UserId) => {
    await prepopulateOrCleanUpRecentFiles(userId);
  },
};

const prepopulateOrCleanUpRecentFiles = async (userId: UserId) => {
  const recentFileslastCleanUpDate = await RecentFilesRepository.findRecentFileslastCleanUpDate(userId);
  if (recentFileslastCleanUpDate === null) {
    // first-time user or user who has never had their recent files indexed
    return await prepopulateRecentFilesList(userId);
  }
  if (recentFileslastCleanUpDate < Date.now() - CLEANUP_DELAY) {
    // user has index but hasn't had it cleaned up in a while
    return await trimRecentFiles(userId);
  }
};

const prepopulateRecentFilesList = async (userId: UserId) => {
  const dbFiles = await KettleMetadataRepository.findFilesByUserId(userId);

  const trimmedRecentFiles = dbFiles
    .filter((f) => f.isStudio)
    .sort((a, b) => b.modifiedDate - a.modifiedDate)
    .slice(0, NUMBER_OF_RECENT_FILES)
    .map((f) => createRecentFilesFileEntry(f));

  await RecentFilesRepository.setRecentFilesForUser(userId, trimmedRecentFiles);
};

const trimRecentFiles = async (userId: UserId) => {
  await RecentFilesRepository.updateRecentFiles(userId, (recentFiles: RecentFilesNode) => {
    const files = Object.values(recentFiles?.files ?? {})
      .map((file) => RecentFileSchema.safeParse(file))
      .filter((result): result is { success: true; data: RecentFile } => result.success)
      .map(({ data }) => data)
      .sort((a, b) => b.modifiedDate - a.modifiedDate);
    const filesToKeep = files.slice(0, NUMBER_OF_RECENT_FILES);
    return {
      lastCleanUpDate: Date.now(),
      files: Object.fromEntries(filesToKeep.map((file) => [file.id, file])),
    };
  });
};
