import { increaseFrameVersion, KettleFrameId } from '../../../../../domain/kettle/types/frame.ts';
import { KettleAudioSampleId, KettleFileId } from '../../../../../domain/kettle/types/shared.ts';
import { KettleRepository } from '../kettle.repository.ts';

export const updateSelectedAudioSampleService = async (
  fileId: KettleFileId,
  frameId: KettleFrameId,
  sampleId: KettleAudioSampleId
) => {
  return KettleRepository.updateFramesInFile(fileId, (existingFrames) => {
    return (existingFrames ?? []).map((fr) =>
      fr.frameId === frameId
        ? {
            ...fr,
            selectedAudioSampleId: sampleId,
            _version: increaseFrameVersion(fr._version),
            selectedSample: fr.samples.find((s) => s.id === sampleId)?.audioURL ?? null,
          }
        : fr
    );
  });
};
