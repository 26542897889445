import { KettleFileId } from '../../../domain/kettle/types/shared';
import { KettleMetadataRepository } from '../db/studio-file-metadata/kettleMetadata.repository.ts';
import { User } from '../../../domain/auth/User';
import { checkIsPaidUser } from '../../../hooks/useIsPaidUser.ts';
import { getActivePackage } from '../../../utils/plans.helpers.tsx';

const MAX_ALLOWED_PARAGRAPHS = 300;

export enum UserAccessTypes {
  PROJECT_NOT_FOUND_OR_USER_IS_NOT_ALLOWED = 0,
  USER_IS_ALLOWED_TO_WRITE_TO_PROJECT = 1,
  // This is just a random number that is different from the other ones above. It is this specific value to make it
  // easy for us to find it in the codebase through a simple text search.
  // If you want to change it, make sure you update KettleEditorPage too, as it is used there as well.
  USER_IS_ADMIN = 758983,
}

export interface PlanBannerInfo {
  at: UserAccessTypes;
  isUserOnFreePlan: boolean;
  maxParagraphs: number;
  activePlanId: string | null;
}

export const getUserPlanSummaryService = async (fileId: KettleFileId, user: User) => {
  const fileMetadata = await KettleMetadataRepository.getFileMetadata(fileId);

  if (!fileMetadata || fileMetadata.userId !== user.id) {
    return {
      at: UserAccessTypes.PROJECT_NOT_FOUND_OR_USER_IS_NOT_ALLOWED,
      isUserOnFreePlan: false,
      maxParagraphs: MAX_ALLOWED_PARAGRAPHS,
      activePlanId: null,
    };
  }

  const isAdmin = user.isAdmin ?? false;

  return {
    at: isAdmin ? UserAccessTypes.USER_IS_ADMIN : UserAccessTypes.USER_IS_ALLOWED_TO_WRITE_TO_PROJECT,
    isUserOnFreePlan: checkIsPaidUser(user) ? false : true,
    maxParagraphs: MAX_ALLOWED_PARAGRAPHS,
    activePlanId: getActivePackage(user)?.id ?? null,
  };
};
