import { z } from 'zod';
import { AddOn, Package } from '../billing/Plan';

// zod valid for user id
export const userIdSchema = z.string().brand('userId');
export type UserId = z.infer<typeof userIdSchema>;

export type User = {
  id: UserId;
  email: string;
  usecase?: unknown;
  role?: string;
  source?: string;
  created_at?: string;
  imageUrl?: string;
  name?: string;
  refreshToken?: string;
  providerId?: string;
  appId?: string;
  usage?: {
    credits?: number;
    appsumo_credits?: number;
    writer_credits?: number;
    appsumo_words?: number;
    words_count?: number;
    words_carry_over?: number;
    chars_count?: number;
    current_metered_char_usage?: number;
  };
  packages?: Record<string, Package>;
  activated?: boolean;
  phoneNumber?: string;
  action?: string;
  add_ons?: Record<string, AddOn>;
  stripe_customer_id?: string;
  type?: string;
  emailVerified?: boolean;
  is_pro?: boolean;
  isAdmin?: boolean;
  isTeamMember?: boolean;
  teamAdmins?: string[];
  isTeamAdmin?: boolean;
  adminOfTeams?: string[];
  singleViewItems?: {
    lastNewsSeenDate?: string;
  };
};
