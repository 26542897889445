import { z } from 'zod';

export const voicePresetSchema = z.enum(['low-latency', 'balanced', 'ft-high-quality', 'ft-real-time']);
export type VoicePreset = z.infer<typeof voicePresetSchema>;

export const kettleFileIdSchema = z.string().brand('KettleFileId');
export type KettleFileId = z.infer<typeof kettleFileIdSchema>;

export const VERSION_ZERO = { seq: 0, uuid: undefined } as const;
export const VersionSchema = z.union([
  z.object({
    seq: z.literal(0),
    uuid: z.undefined(),
  }),
  z.object({
    seq: z.number(),
    uuid: z.string(),
  }),
]);
export type Version = z.infer<typeof VersionSchema>;

export const MediaURL = z.string().brand('__MediaURL');
export const AudioURLSchema = MediaURL.brand('__AudioURL');
export type AudioURL = z.infer<typeof AudioURLSchema>;

export const KettleAudioSampleIdSchema = z.string().brand('__KettleAudioSampleId');
export type KettleAudioSampleId = z.infer<typeof KettleAudioSampleIdSchema>;
