import { Modal } from './Modal';
import { useAuth } from '../contexts/auth.context';
import { sendEmailVerification, applyActionCode } from 'firebase/auth';
import { useState, useEffect, useCallback } from 'react';
import { Spinner } from './Spinner';
import { auth } from '../utils/firebase';
import { useLocation } from 'react-router-dom';
import { useUser } from '../contexts/user.context';
import { useIsPaidUser } from '../hooks/useIsPaidUser';

interface VerifyEmailModalProps {
  maxWidth?: string;
}

interface VerificationError {
  code: string;
  message: string;
}

const ERROR_MESSAGES = {
  'Firebase: Error (auth/too-many-requests).': 'Too many requests. Please try again later.',
  'Firebase: Error (auth/invalid-action-code).':
    'The link is invalid or expired. Please request a new verification link.',
  'Firebase: Error (auth/invalid-continue-uri).':
    'The link is invalid or expired. Please request a new verification link.',
  'Firebase: Error (auth/invalid-sender).': 'The link is invalid or expired. Please request a new verification link.',
};

export function VerifyEmailModal({ maxWidth = '460px' }: VerifyEmailModalProps) {
  const location = useLocation();
  const { currentUser } = useAuth();

  const { user } = useUser();
  const isPaidUser = useIsPaidUser();

  const [sendingEmail, setSendingEmail] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (!currentUser || currentUser.emailVerified) {
      setIsChecking(false);
      return;
    }

    const verify = async () => {
      setIsChecking(true);
      try {
        const params = new URLSearchParams(location.search);
        const oobCode = params.get('oobCode');
        const mode = params.get('mode');

        if (mode === 'verifyEmail' && oobCode) {
          await applyActionCode(auth, oobCode);
          window.location.reload();
        }
      } catch (error) {
        const verificationError = error as VerificationError;
        setError(
          verificationError.message || 'Failed to verify email. Please try again or request a new verification link.'
        );
      } finally {
        setIsChecking(false);
      }
    };

    verify();
  }, [location.search, currentUser]);

  const handleResendEmail = useCallback(async () => {
    if (!currentUser) return;

    setSendingEmail(true);
    setError(null);

    try {
      await sendEmailVerification(currentUser);
      setEmailSent(true);
      setTimeout(() => setEmailSent(false), 3000); // Reset after 3 seconds
    } catch (error) {
      const verificationError = error as VerificationError;
      setError(verificationError.message || 'Failed to send verification email. Please try again.');
    } finally {
      setSendingEmail(false);
    }
  }, [currentUser]);

  if (!currentUser || !user || currentUser.emailVerified || user.emailVerified || isPaidUser) {
    return null;
  }

  if (isChecking) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <Spinner label="Checking verification status..." />
      </div>
    );
  }

  return (
    <Modal
      maxWidth={maxWidth}
      open={!currentUser.emailVerified}
      persistent
      setOpen={() => {}}
      aria-labelledby="verify-email-title"
    >
      <div className="flex flex-col text-center">
        <div className="flex flex-col items-center justify-center py-12 max-tablet:p-4">
          <h1 id="verify-email-title" className="mb-2 text-xl font-semibold">
            Verify your email
          </h1>

          <p className="text-xs mb-4">
            <span>Please verify your email address by clicking the link sent to </span>
            <span className="font-semibold">{currentUser.email || 'your email address'}</span>
          </p>

          <button
            type="button"
            onClick={handleResendEmail}
            className="font-semibold py-2 px-[13px] text-[12px] leading-[20px] bg-gray-700 rounded-md hover:opacity-90 duration-200 ease-out disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={sendingEmail}
            aria-live="polite"
          >
            {sendingEmail ? <Spinner label="Sending email..." /> : 'Resend Email'}
          </button>

          {emailSent && (
            <p className="mt-2 text-sm text-green-500" role="status">
              Verification email sent successfully!
            </p>
          )}

          {error && (
            <p className="mt-2 text-sm text-red-500" role="alert">
              {ERROR_MESSAGES[error as keyof typeof ERROR_MESSAGES] || error}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
}
