import { useEffect, useRef, useState } from 'react';
import { reportKettleError } from '../utils/reportKettleIssue';
import { KettleFileId } from '../../../domain/kettle/types/shared';
import { getUserPlanSummaryService, PlanBannerInfo } from '../services/getUserPlanSummaryService';
import { useUser } from '../../../contexts/user.context';

export const PLAN_SUMMARY_STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  LOADED_NO_ACCESS: 'LOADED_NO_ACCESS',
  LOADED_WITH_ACCESS: 'LOADED_WITH_ACCESS',
};

export function usePlanSummary(fileId: KettleFileId, isOnHold: boolean) {
  const { user } = useUser();
  const [planSummaryLoadingState, setPlanSummaryLoadingState] = useState(PLAN_SUMMARY_STATUS.LOADING);

  const [planSummaryData, setPlanSummaryData] = useState<PlanBannerInfo | null>(null);

  const hasStartedLoading = useRef(false);

  useEffect(() => {
    if (hasStartedLoading.current || !fileId || !user || isOnHold) return;
    hasStartedLoading.current = true;

    getUserPlanSummaryService(fileId, user)
      .then((data: PlanBannerInfo) => {
        setPlanSummaryData(data);
        setPlanSummaryLoadingState(
          data.at ? PLAN_SUMMARY_STATUS.LOADED_WITH_ACCESS : PLAN_SUMMARY_STATUS.LOADED_NO_ACCESS
        );
      })
      .catch((e) => {
        reportKettleError(fileId, 'Error while fetching plan banner info data: ' + e.message, e);
        setPlanSummaryLoadingState(PLAN_SUMMARY_STATUS.ERROR);
      });
  }, [fileId, user, isOnHold]);

  return { planSummaryLoadingState, planSummaryData };
}
