// kettleLegacyRTDB.repository.ts which has the logic to get the project from the realtime database
//     databaseURL: CONFIG.firebase.databaseURLForAudioFrames,

import { ref } from 'firebase/database';
import { get } from 'firebase/database';
import { KettleFileId } from '../../../domain/kettle/types/shared';
import { audioFramesDB } from '../../../utils/firebase';
import { KettleFile } from '../../../domain/kettle/types/file';

const KETTLE_FILES_PATH = 'audioFrames';

export const KettleLegacyRTDBRepository = {
  async getFile(fileId: KettleFileId): Promise<KettleFile | null> {
    const snapshot = await get(ref(audioFramesDB, `${KETTLE_FILES_PATH}/${fileId}`));
    return snapshot.exists() ? (snapshot.val() as KettleFile) : null;
  },
};
