import { z } from 'zod';
import { kettleFileIdSchema } from './types/shared';

export const RecentFileSchema = z.object({
  id: kettleFileIdSchema,
  title: z.string().nullable(),
  modifiedDate: z.number().int().positive(),
  isStudio: z.boolean().optional(),
});

export const RecentFilesNodeSchema = z.object({
  lastCleanUpDate: z.number().int().positive(),
  files: z.record(kettleFileIdSchema, RecentFileSchema).optional(),
});

export type RecentFile = z.infer<typeof RecentFileSchema>;
export type RecentFilesNode = z.infer<typeof RecentFilesNodeSchema>;

export const NUMBER_OF_RECENT_FILES = 5;
export const CLEANUP_DELAY = 1000 * 60 * 60 * 24 * 7; // 7 days
