import { firestoreDB } from '../../../../utils/firebase.ts';
import { doc, getDoc, setDoc, updateDoc, runTransaction, deleteDoc } from 'firebase/firestore';
import { FIRESTORE_COLLECTIONS } from '../../../../constants/firestore.ts';
import { KettleFrame } from '../../../../domain/kettle/types/frame.ts';
import { KettleFileId } from '../../../../domain/kettle/types/shared.ts';
import { KettleFile } from '../../../../domain/kettle/types/file.ts';
import {
  AUDIO_FRAMES__EDITOR_CONFIG_KEY,
  AUDIO_FRAMES__FRAMES_KEY,
} from '../../../../domain/kettle/types/constants.ts';
import { KettleEditorConfig } from '../../../../domain/kettle/types/editor.ts';
import { kettleMutex } from '../../kettleMutex.ts';

export const KettleRepository = {
  async getFile(fileId: KettleFileId): Promise<KettleFile | null> {
    const document = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES, fileId));
    return document.exists() ? (document.data() as KettleFile) : null;
  },

  async createFile(file: KettleFile): Promise<void> {
    await setDoc(doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES, file.projectId), file);
  },

  async updateFile(fileId: KettleFileId, newData: Partial<KettleFile>): Promise<void> {
    await updateDoc(doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES, fileId), newData);
  },

  async updateFileConfig(fileId: KettleFileId, newData: Partial<KettleEditorConfig>): Promise<void> {
    await updateDoc(doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES, fileId, AUDIO_FRAMES__EDITOR_CONFIG_KEY), {
      ...newData,
    });
  },

  async updateFramesInFile(
    fileId: KettleFileId,
    transactionUpdate: (existingFrames: KettleFrame[] | null) => KettleFrame[]
  ): Promise<void> {
    const docRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES, fileId);

    // TODO handle timeout error nicely in the UI
    // Under extreme conditions (e.g. transactions are taking longer than 30s), the mutex below will timeout and throw
    // an exception. We should handle it nicely in the UI and maybe retry from the source (that is, the point in
    // kettle where the command came from).
    await kettleMutex.runExclusive(() => {
      return runTransaction(firestoreDB, async (transaction) => {
        const docSnap = await transaction.get(docRef);
        if (!docSnap.exists()) {
          return null;
        }

        const existingFrames = docSnap.data()?.frames as KettleFrame[] | null;
        const updatedFrames = transactionUpdate(existingFrames);

        transaction.update(docRef, { [AUDIO_FRAMES__FRAMES_KEY]: updatedFrames });
      });
    });
  },
  async deleteFile(fileId: KettleFileId) {
    await deleteDoc(doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES, fileId));
  },
};
