import { Fragment, useEffect, useState } from 'react';
import { PlanT, studioPlans, apiPlans, CycleType } from '../../utils/plans.helpers.tsx';
import { useUser } from '../../contexts/user.context';
import { TeamMembersBlockingUI } from '../../components/TeamMembersBlockingUI';
import { Tabs } from '../../components/Tabs';
import { RadioList } from '../../components/RadioList';
import doordashLogo from '../../assets/trustedteams/doordash-logo.svg';
import salesforceLogo from '../../assets/trustedteams/salesforce-logo.svg';
import amazonLogo from '../../assets/trustedteams/amazon-logo.svg';
import reutersLogo from '../../assets/trustedteams/reuters-logo.svg';
import verizonLogo from '../../assets/trustedteams/verizon-logo.svg';
import hyundaiLogo from '../../assets/trustedteams/hyundai-logo.svg';
import { PricingPlanCard } from './PricingPlanCard.tsx';
import { CodeBracketIcon } from '@heroicons/react/24/outline';
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';

export const billingCycles: { id: CycleType; title: string; disc: string }[] = [
  { id: 'monthly', title: 'Monthly', disc: '' },
  { id: 'annually', title: 'Annually', disc: 'save 50%' },
];

const defaultBillingCycle = billingCycles[1];

const tabs = [
  {
    value: 'studio',
    label: 'Studio',
    icon: <CursorArrowRaysIcon className="sm:w-12 w-8" />,
    description: 'Online Studio for creating ultra-realistic AI voiceovers.',
  },
  {
    value: 'api',
    label: 'API',
    icon: <CodeBracketIcon className="sm:w-12 w-8" />,
    description: 'Real-time voice API for Conversational AI and applications',
  },
];

const trustedTeamsImages = [
  { alt: 'DoorDash', src: doordashLogo, className: 'w-[7.5rem]' },
  { alt: 'Salesforce', src: salesforceLogo, className: 'w-[3.8rem]' },
  { alt: 'Amazon', src: amazonLogo, className: 'w-[5.2rem]' },
  { alt: 'Reuters', src: reutersLogo, className: 'w-[7.7rem]' },
  { alt: 'Verizon', src: verizonLogo, className: 'w-[5.5rem]' },
  { alt: 'Hyundai', src: hyundaiLogo, className: 'w-[7.0rem]' },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function PricingPage() {
  const { user } = useUser();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedBillingCycle, setBillingCycle] = useState(defaultBillingCycle);
  const query = useQuery();
  const planType = query.get('planType');

  const plans = selectedTab === tabs[0] ? studioPlans : apiPlans;

  const targetPlans = plans.filter((plan: PlanT) => plan.cycle === selectedBillingCycle.id);

  useEffect(() => {
    if (planType) {
      setSelectedTab(() => (planType === 'api' ? tabs[1] : tabs[0]));
    }
  }, [planType]);

  useEffect(() => {
    // Show only monthly billing cycle for API plans
    if (selectedTab === tabs[1]) {
      setBillingCycle(billingCycles[0]);
    }
  }, [selectedTab]);

  if (user?.isTeamMember) {
    return <TeamMembersBlockingUI pageName="Pricing" />;
  }

  return (
    <div className="dark:text-neutral-50 pb-16">
      <div className="mb-12 flex items-center justify-between opacity-70 max-lg:hidden">
        <div className="text-xs">TRUSTED BY TEAMS AT</div>
        {trustedTeamsImages.map((image) => (
          <img src={image.src} alt={image.alt} className={image.className} />
        ))}
      </div>
      <div className="md:mb-5 mb-3 flex flex-col items-center justify-center lg:px-[1rem] md:px-12">
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs} />
        {/* API Prices are monthly only for now */}
        {selectedTab.value !== 'api' ? (
          <RadioList
            options={billingCycles}
            selectedOption={selectedBillingCycle}
            setSelectedOption={setBillingCycle}
          />
        ) : null}
      </div>
      <div className="flex flex-1 flex-wrap items-stretch justify-center gap-4">
        {targetPlans.map((plan: PlanT) => (
          <Fragment key={plan.key}>
            <PricingPlanCard plan={plan} billingCycle={selectedBillingCycle.id} />
          </Fragment>
        ))}
      </div>
    </div>
  );
}
