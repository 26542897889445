import { Link } from 'react-router-dom';
import { defaultFileName } from '../../domain/files/File.ts';
import { useState } from 'react';
import { formatUnixMillisecondsToDifferenceFromNow } from '../../utils/dateUtils.ts';
import { Checkbox } from '../../components/Checkbox.tsx';
import { Pagination, postsPerPage } from '../../components/Pagination.tsx';
import { KettleFileMetadata } from '../../domain/kettle/types/file.ts';

export function FilesTable({
  files,
  selectedFiles,
  setSelectedFiles,
}: {
  files: KettleFileMetadata[] | null;
  selectedFiles: string[];
  setSelectedFiles: (files: string[]) => void;
}) {
  const [currentPage, setCurrentPage] = useState(1);

  if (!files) return null;

  const totalRows = files.length;
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = files.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
      <table className="mb-1 mt-6 w-full overflow-hidden whitespace-nowrap rounded-lg text-left">
        <tbody>
          {currentPosts?.map((f) => (
            <tr key={f.id}>
              <td className="w-8 p-4 pl-0.5">
                <Checkbox
                  name="table-checkbox-column"
                  value={f.id}
                  checked={selectedFiles.includes(f.id)}
                  onChange={(e) =>
                    setSelectedFiles(
                      e.target.checked ? [...selectedFiles, f.id] : selectedFiles.filter((p) => p !== f.id)
                    )
                  }
                />
              </td>
              <td className="p-4">
                <div className="fs-center flex gap-x-4">
                  <div className="truncate">
                    <Link
                      to={`/studio/files/${f.id}`}
                      className="font-semibold text-neutral-300 hover:text-neutral-200"
                    >
                      <span className="font-normal" title={f.title}>
                        {f.title ? (f.title.length > 30 ? f.title.slice(0, 30) + '...' : f.title) : defaultFileName}
                      </span>
                      <span className="bg-primary text-white font-semibold text-xs rounded-md ml-2 py-0.5 px-1.5">
                        {f.model ?? ''}
                      </span>
                    </Link>
                    <dl className="font-normal tablet:hidden">
                      <dd className="mt-1 truncate text-neutral tablet:hidden">
                        {formatUnixMillisecondsToDifferenceFromNow(f.modifiedDate)}
                      </dd>
                    </dl>
                  </div>
                </div>
              </td>
              <td className="hidden px-6 py-3 tablet:table-cell">
                <div className="text-right">
                  <span>{formatUnixMillisecondsToDifferenceFromNow(f.modifiedDate)}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-8">
        <Pagination totalRows={totalRows} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
    </>
  );
}
