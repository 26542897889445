import clsx from 'clsx';
import { CycleType } from '../utils/plans.helpers';

export function RadioList({
  options,
  selectedOption,
  setSelectedOption,
}: {
  options: { id: CycleType; title: string; disc: string }[];
  selectedOption: { id: CycleType; title: string };
  setSelectedOption: (option: { id: CycleType; title: string; disc: string }) => void;
}) {
  return (
    <fieldset className="flex flex-wrap flex-col w-11/12 items-start gap-x-8 sm:mt-10 mt-3 lg:flex-row lg:items-center justify-center mx-auto">
      <div className="flex flex-col md:flex-row justify-center items-start gap-x-8 gap-y-4">
        {options.map((option) => (
          <div key={option.id} className="inline-flex items-center gap-2">
            <label className="relative flex cursor-pointer items-center rounded-full" htmlFor={option.id}>
              <input
                id={option.id}
                name="notification-method"
                type="radio"
                checked={selectedOption.id === option.id}
                onChange={() => setSelectedOption(option)}
                className="peer relative h-5 w-5 cursor-pointer rounded-full bg-transparent text-neutral-800 !outline-0 !outline-offset-0 !ring-transparent !ring-offset-0 !ring-offset-transparent border-2 border-neutral-600 hover:!border-primary-green-600 checked:!border-primary-green-600 transition-all"
              />
              <div className="absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-primary-green-600 transition-opacity opacity-0 peer-checked:opacity-100">
                <svg className="h-2.5 w-2.5" viewBox="0 0 16 16" fill="currentColor">
                  <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                </svg>
              </div>
            </label>
            <label htmlFor={option.id} className={clsx('mt-px cursor-pointer select-none text-neutral-50')}>
              {option.title}
              {option.disc && (
                <span className="ml-2 text-primary-green-700 font-semibold border border-primary-green-700 rounded px-2 text-base capitalize">
                  {option.disc}
                </span>
              )}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
