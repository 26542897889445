// src/domain/kettle/types/sample.ts
import { z } from 'zod';
import { audioOptionsSchema } from './audio';
import { voicePresetSchema, AudioURLSchema, KettleAudioSampleIdSchema } from './shared';

export const KettleAudioSampleStatusSchema = z.enum(['STREAMING', 'GENERATING', 'COMPLETED', 'FAILED']);
export type KettleAudioSampleStatus = z.infer<typeof KettleAudioSampleStatusSchema>;

export const KettleAudioSampleSchema = z.object({
  version: z.literal('2'),
  id: KettleAudioSampleIdSchema,
  audioURL: AudioURLSchema,
  type: z.enum(['STREAMING', 'LEGACY']),
  status: KettleAudioSampleStatusSchema,

  requestedAt: z.string(),
  streamingRequestedAt: z.string().nullable(),
  streamingStartedAt: z.string().nullable(),
  streamingCompletedAt: z.string().nullable(),
  finishedAt: z.string().nullable(),

  size: z.number().nullable(),
  duration: z.number().nullable(),
  options: audioOptionsSchema,
  preset: voicePresetSchema.nullable(),
  title: z.string(),

  // legacy fields
  requestGenerationTime: z.string().nullable(),
  requestCompletionTime: z.string().nullable(),
  timestamp: z.string().nullable(),
});

export type KettleAudioSample = z.infer<typeof KettleAudioSampleSchema>;
