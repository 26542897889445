// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useCallback, useEffect, useState } from 'react';
import KettleEditor, { UserNotificationType } from '@playht/component-kettle-editor';
import '@playht/component-kettle-editor/index.css';
import { useParams, useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { generatePreviewsForFrameAPI, exportTimelineMediaAPI } from './API/kettle.requests.js';
import Loader from './components/Loader.jsx';
import { usePreventUnload } from './hooks/usePreventUnload';
import useVoices from './hooks/useVoices.ts';
import { useFirebaseIsConnectedListener } from './hooks/useFirebaseIsConnectedListener';
import { useAvailableVoicePresets } from './hooks/useAvailableVoicePresets';
import { useAuth } from '../../contexts/auth.context';
import { useUser } from '../../contexts/user.context.tsx';
import { LegacyInstantVoiceCloningModal } from '../VoiceCloning/LegacyInstantVoiceCloningModal.tsx';
import { useCreateAndOpenFile } from '../../hooks/files.hooks';
import { AddNewStyleModal } from '../VoiceCloning/AddNewStyleModal';
import { updateClonedVoiceAPI } from '../../API/voiceCloning.requests.ts';
import FeedbackModal from './components/FeedbackModal.jsx';
import { eventsTracker } from '../../infra/analytics/eventsTracker';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { getCreditTypeForUser } from '../../domain/credit/CreditType';
import { DEFAULT_FILE_MODEL } from '../../domain/files/File.ts';
import { useFirestoreListener } from '../../hooks/useFirestoreListener.hook.ts';
import { reportKettleError, reportKettleInfo } from './utils/reportKettleIssue.js';
import { calculateLoadingPercent } from './utils/calculateLoadingPercent';
import { KettleLoadingError } from './components/KettleLoadingError.tsx';
import { generateUserData } from './utils/generateUserData.js';
import { PLAN_SUMMARY_STATUS, usePlanSummary } from './hooks/usePlanSummary';
import { getUniqueAudioSamples } from './db/studio-file/frame/audio-sample/getUniqueAudioSamples.ts';
import { notifyUser } from './utils/notifyUser.js';
import { MIGRATION_STATUS, useMigrateKettleFile } from './hooks/useMigrateKettleFile';
import { FIRESTORE_COLLECTIONS } from '../../constants/firestore.ts';
import { updateEditorTitleService } from './db/studio-file-metadata/updateEditorTitleService.ts';
import { updateEditorStateService } from './db/studio-file/updateEditorStateService.ts';
import { updateEditorPresetService } from './db/studio-file-metadata/updateEditorPresetService.ts';
import { createNewEmptyFramesService } from './db/studio-file/createNewEmptyFramesService.ts';
import { updateSelectedAudioSampleService } from './db/studio-file/frame/updateSelectedAudioSampleService.ts';
import { updateAudioSampleTitleService } from './db/studio-file/frame/audio-sample/updateAudioSampleTitleService.ts';
import { removeAudioSampleService } from './db/studio-file/frame/audio-sample/removeAudioSampleService.ts';
import { updateFrameOptionsService } from './db/studio-file/frame/updateFrameOptionsService.ts';
import { updateFramesDelaysService } from './db/studio-file/frame/updateFramesDelaysService.ts';
import { KettleFileId } from '../../domain/kettle/types/shared.ts';
import { UpgradeModal } from '../../components/NewFilePicker/UpgradeModal.tsx';

export function KettleEditorPage() {
  const [searchParams] = useSearchParams();
  const { id: projectId } = useParams<{ id: KettleFileId | undefined }>();
  const disableAnimation = searchParams.get('animation') === 'false';
  const enableDebugger = searchParams.get('debug') === 'true';

  const { currentUser: user } = useAuth();
  const { user: userInfo } = useUser();

  const { createAndOpenFile } = useCreateAndOpenFile();
  const [voiceCloningOpen, setVoiceCloningOpen] = useState(false);
  const [addNewStyleOpen, setAddNewStyleOpen] = useState({ parentVoiceId: null, open: false });
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [upgradeModalIsOpen, setUpgradeModalOpen] = useState(false);
  const availableVoicePresets = useAvailableVoicePresets();
  const { featureFlagsLoading, featureFlags } = useFeatureFlags();

  const { migrationStatus } = useMigrateKettleFile(projectId, user?.uid);

  const [editorMetadata, editorMetadataLoadStatus] = useFirestoreListener(
    `${FIRESTORE_COLLECTIONS.KETTLE_FILES_METADATA}/${projectId}`,
    { isOnHold: migrationStatus !== MIGRATION_STATUS.SUCCESS }
  );

  const [audioFramesData, audioFramesLoadStatus] = useFirestoreListener(
    `${FIRESTORE_COLLECTIONS.KETTLE_FILES}/${projectId}`,
    {
      transform: (firestoreData) => ({
        editorConfig: firestoreData?.editorConfig ?? {},
        exportedMedia: firestoreData?.exportedMedia ?? {},
        frames: (firestoreData?.frames ?? []).map((fr) => ({
          ...fr,
          samples: getUniqueAudioSamples(fr.samples ?? []),
        })),
        serializedEditorState: firestoreData?.serializedEditorState
          ? JSON.stringify(firestoreData.serializedEditorState)
          : undefined,
      }),
      initialState: {
        editorConfig: {},
        exportedMedia: {},
        frames: [],
        serializedEditorState: undefined,
      },
      isOnHold: migrationStatus !== MIGRATION_STATUS.SUCCESS,
    }
  );

  const { editorConfig, exportedMedia, frames, serializedEditorState } = audioFramesData;

  const { planSummaryLoadingState, planSummaryData } = usePlanSummary(
    projectId,
    migrationStatus !== MIGRATION_STATUS.SUCCESS
  );

  const [pendingPersistEditorState, setPendingPersistEditorState] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const persistEditorStateChange = useCallback(
    debounce(async (editorState) => {
      const persistEditorChanges = async () =>
        updateEditorStateService(projectId, editorState.toJSON())
          .catch((e) => {
            notifyUser(
              UserNotificationType.ERROR,
              'Error while saving your editor changes.\n' +
                'Please check your internet connection before proceeding.\n' +
                'If the error persists, try refreshing the page.',
              () => persistEditorChanges()
            );
            reportKettleInfo(projectId, `Error while saving editor changes: ${e.message}`, e);
          })
          .then(() => setPendingPersistEditorState(false));
      // noinspection ES6MissingAwait
      persistEditorChanges();
    }, 10000),
    [user, projectId, planSummaryData]
  );

  const handleEditorStateChange = (editorState) => {
    setPendingPersistEditorState(true);
    persistEditorStateChange(editorState);
  };

  useEffect(() => {
    return () => persistEditorStateChange.flush();
  }, [persistEditorStateChange]);

  const { data: availableVoices, refreshVoices } = useVoices();
  const defaultVoice = searchParams.get('voice');

  usePreventUnload(() => pendingPersistEditorState);

  const reportAnalyticsEvent = (event) => eventsTracker.sendMixpanelEvent(event.type, generateUserData(user, userInfo));

  const [userIsConnected, userIsConnectedLoadStatus] = useFirebaseIsConnectedListener();

  const updateEditorTitle = async (newTitle, isAutogenerated) => {
    return updateEditorTitleService(user?.uid, projectId, newTitle, isAutogenerated);
  };

  const updateEditorPreset = async ({ newPreset }) => {
    return updateEditorPresetService(projectId, newPreset);
  };

  const createNewEmptyFrames = async (frameIdsAndOptions) => {
    return createNewEmptyFramesService(projectId, frameIdsAndOptions);
  };

  const generatePreviewsForFrame = async ({ frameId, options, preset, text, textContentHash }) => {
    return generatePreviewsForFrameAPI(
      featureFlags.ENABLE_STREAMING &&
        (editorMetadata?.model === 'PlayHT2.0' ||
          editorMetadata?.model === 'PlayHT2.0-gargamel' ||
          editorMetadata?.model === 'Play3.0' ||
          editorMetadata?.model === 'PlayDialog'),
      user.uid,
      await user.getIdToken(),
      projectId,
      frameId,
      options,
      preset,
      text,
      textContentHash
    );
  };

  const updateSelectedAudioSample = async ({ frameId, audioURL }) => {
    return updateSelectedAudioSampleService(projectId, frameId, audioURL);
  };

  const updateAudioSampleTitle = async ({ frameId, audioURL, newTitle }) => {
    return updateAudioSampleTitleService(projectId, frameId, audioURL, newTitle);
  };

  const removeAudioSample = async ({ frameId, audioURL }) => {
    return removeAudioSampleService(projectId, frameId, audioURL);
  };

  const exportTimelineMedia = async (fileName, videoURL, audioURLsWithOffsets) => {
    return exportTimelineMediaAPI({
      token: await user.getIdToken(),
      projectId,
      fileName,
      audioFiles: audioURLsWithOffsets,
    });
  };

  const updateFrameOptions = async (frameId, newOptions, changeOptionsType, previousOptions) => {
    return updateFrameOptionsService(projectId, frameId, newOptions, changeOptionsType, previousOptions);
  };

  const updateFramesDelays = async (frameIdsWithNewDelays) => {
    return updateFramesDelaysService(projectId, frameIdsWithNewDelays);
  };

  const updateClonedVoice = useCallback(
    async (voiceId, gender) => {
      await updateClonedVoiceAPI(await user.getIdToken(), voiceId, gender);
      refreshVoices();
    },
    [user, refreshVoices]
  );

  if (migrationStatus === MIGRATION_STATUS.ERROR)
    return (
      <KettleLoadingError
        message="We apologize for the inconvenience, but an unexpected error occurred while trying to update your project to the latest version of our editor."
        allowRetry
      />
    );
  if (
    migrationStatus === MIGRATION_STATUS.PROJECT_NOT_FOUND ||
    planSummaryLoadingState === PLAN_SUMMARY_STATUS.LOADED_NO_ACCESS
  ) {
    return (
      <KettleLoadingError message="The requested project cannot be found or you do not have sufficient privileges to access it." />
    );
  }
  if (planSummaryLoadingState === PLAN_SUMMARY_STATUS.ERROR) {
    return <KettleLoadingError message="Unable to complete your request due to a network error." allowRetry />;
  }

  const loadingPercent = calculateLoadingPercent([
    migrationStatus !== MIGRATION_STATUS.LOADING,
    planSummaryLoadingState !== PLAN_SUMMARY_STATUS.LOADING,
    editorMetadataLoadStatus === 'LISTENING',
    editorConfig,
    audioFramesLoadStatus === 'LISTENING',
    userIsConnectedLoadStatus === 'LISTENING',
    !featureFlagsLoading,
  ]);

  if (loadingPercent < 100) {
    const currentlyLoadingItem = loadingPercent < 40 ? 'voices' : loadingPercent < 70 ? 'frames' : 'presets';
    return <Loader spinnerLabel={`Loading editor ${currentlyLoadingItem}... ${loadingPercent}%`} />;
  }

  return (
    <>
      <UpgradeModal
        open={upgradeModalIsOpen}
        setOpen={setUpgradeModalOpen}
        modalDescription="Upgrade to unlock unlimited file downloads and exports"
      />
      <LegacyInstantVoiceCloningModal
        open={voiceCloningOpen}
        setOpen={setVoiceCloningOpen}
        onCloneCompleted={(voiceId) => createAndOpenFile({ defaultVoice: voiceId })}
      />
      <AddNewStyleModal
        open={addNewStyleOpen.open}
        setOpen={(value) => setAddNewStyleOpen({ ...addNewStyleOpen, open: value })}
        parentVoiceId={addNewStyleOpen.parentVoiceId}
        onCloneCompleted={refreshVoices}
      />
      <FeedbackModal
        open={feedbackModalOpen}
        setOpen={setFeedbackModalOpen}
        projectId={projectId}
        editorPreset={editorConfig.editorPreset}
      />
      <KettleEditor
        projectId={projectId}
        userInfo={userInfo}
        creditType={getCreditTypeForUser(userInfo)}
        featureFlags={featureFlags}
        userIsConnected={userIsConnected}
        updateEditorTitle={updateEditorTitle}
        updateEditorPreset={updateEditorPreset}
        createNewEmptyFrames={createNewEmptyFrames}
        generatePreviewsForFrame={generatePreviewsForFrame}
        updateSelectedAudioSample={updateSelectedAudioSample}
        updateAudioSampleTitle={updateAudioSampleTitle}
        removeAudioSample={removeAudioSample}
        exportTimelineMedia={exportTimelineMedia}
        updateFrameOptions={updateFrameOptions}
        updateFramesDelays={updateFramesDelays}
        editorTitle={editorMetadata?.title}
        editorModel={editorMetadata?.model ?? DEFAULT_FILE_MODEL}
        isEditorTitleAutogenerated={editorMetadata?.isTitleAutogenerated ?? true}
        editorConfig={editorConfig}
        availableVoicePresets={availableVoicePresets}
        initialEditorState={serializedEditorState}
        onEditorStateChange={handleEditorStateChange}
        exportedMedia={exportedMedia ?? {}}
        framesState={frames}
        defaultVoice={defaultVoice}
        availableVoices={availableVoices}
        planBannerInfo={planSummaryData}
        // no need to have the back button on roost
        // onClickBack={() => (window.location.href = '/app/audio-files?tab=ultra')}
        notifyUser={notifyUser}
        reportError={(message, error) => reportKettleError(projectId, message, error)}
        reportEvent={reportAnalyticsEvent}
        enableAnimation={!disableAnimation}
        enableDebugger={enableDebugger}
        forceTheme="dark"
        createNewVoice={() => setVoiceCloningOpen(true)}
        createNewStyle={(parentVoiceId) => setAddNewStyleOpen({ parentVoiceId, open: true })}
        showFeedbackModal={() => setFeedbackModalOpen(true)}
        updateClonedVoice={updateClonedVoice}
        showUpgradeModal={() => setUpgradeModalOpen(true)}
      />
    </>
  );
}
