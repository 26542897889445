import { UserId } from '../../../domain/auth/User.ts';
import { KettleFileId } from '../../../domain/kettle/types/shared.ts';
import { KettleRepository } from './studio-file/kettle.repository.ts';
import { KettleMetadataRepository } from './studio-file-metadata/kettleMetadata.repository.ts';
import { RecentFilesRepository } from './recent-files/RecentFilesRepository.ts';

export const deleteFileService = async (userId: UserId, fileId: KettleFileId) => {
  await KettleMetadataRepository.deleteFileMetadata(fileId);
  await KettleRepository.deleteFile(fileId);
  await RecentFilesRepository.removeRecentFile(userId, fileId);
};
