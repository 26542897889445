import axios from 'axios';
import { CONFIG } from '../../../config';
import { kettleMutex } from '../kettleMutex';

export const generatePreviewsForFrameAPI = async (
  isStreamingRequest,
  userId,
  token,
  projectId,
  frameId,
  options,
  preset,
  text,
  textContentHash
) => {
  if (isStreamingRequest) {
    const streamingOptions = {
      voice: options.style ?? options.voice,
      speed: options.speed,
      text,
      ...options,
    };

    await kettleMutex.runExclusive(async () => {
      await axios
        .post(
          `${CONFIG.roostBffAPIBaseUrl}/tts/request-streaming-generation`,
          {
            kettleFileId: projectId,
            frameId,
            options: streamingOptions,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .catch((e) => Promise.reject({ e, message: e.message, status: e.response?.status, ...e.response?.data }));
    });
    return; // void return
  }
  return axios
    .post(`${CONFIG.playStudioAPIBaseUrl}/process-audio`, {
      userId,
      size: 1,
      voice: options.style ?? options.voice,
      preset,
      speed: options.speed,
      content: text,
      hash: frameId,
      projectId,
      token,
      textContentHash,
    })
    .catch((e) => Promise.reject({ e, message: e.message, status: e.response?.status, ...e.response?.data }));
};

export const exportTimelineMediaAPI = async ({ token, projectId, fileName, audioFiles }) => {
  return axios
    .post(`${CONFIG.playStudioAPIBaseUrl}/kettle/${projectId}/mixer`, {
      token,
      projectId,
      fileName,
      audioFiles,
    })
    .then((r) => r.data?.requestId);
};
