import { useState } from 'react';
import { Checkbox } from '../../components/Checkbox.tsx';
import { Pagination, postsPerPage } from '../../components/Pagination.tsx';
import { VCRequestOrVoice } from '../../utils/VC.helpers.ts';
import { Button } from '../../components/Button.tsx';
import { ShareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CursorArrowRaysIcon } from '@heroicons/react/24/solid';
import { DeleteVoiceCloneModal } from './DeleteVoiceCloneModal.tsx';
import { VCShareModal } from './VCShareModal.tsx';
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline/index.js';
import { useCreateAndOpenFile } from '../../hooks/files.hooks.ts';
import { WithTooltip } from '../../components/WithTooltip/WithTooltip.tsx';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

export function VCTable({
  rows,
  selectedRows,
  setSelectedRows,
}: {
  rows: VCRequestOrVoice[] | null;
  selectedRows: string[];
  setSelectedRows: (rows: string[]) => void;
}) {
  const [currentPage, setCurrentPage] = useState(1);

  if (!rows) return null;

  const totalRows = rows.length;
  const indexOfLastRow = currentPage * postsPerPage;
  const indexOfFirstRow = indexOfLastRow - postsPerPage;
  const currentRows = rows.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div>
      <div className="relative overflow-auto">
        <table className="mb-1 mt-6 w-full overflow-auto text-left">
          <tbody>
            {currentRows?.map((row) => (
              <tr key={row.id}>
                <td className="w-8 p-4 pl-0.5 max-tablet:px-0.5 max-tablet:align-top">
                  <Checkbox
                    name="table-checkbox-column"
                    value={row.id}
                    checked={selectedRows.includes(row.id)}
                    disabled={!row.isVoice && row.status !== 'complete'}
                    onChange={(e) =>
                      setSelectedRows(
                        e.target.checked ? [...selectedRows, row.id] : selectedRows.filter((p) => p !== row.id)
                      )
                    }
                  />
                </td>
                <td className="p-4">
                  <div className="flex flex-col items-stretch justify-center gap-3">
                    <div className="font-semibold">
                      <span className="truncate font-normal" title={row.name}>
                        {row.name.length > 30 ? row.name.slice(0, 30) + '...' : row.name}
                        {row.isZeroShot && (
                          <span className="ml-2  font-semibold text-xs  text-neutral-100">
                            <span className="bg-primary rounded-md mx-1 py-0.5 px-1.5"> PlayHT2.0 </span>
                            <span className="bg-primary rounded-md mx-1 py-0.5 px-1.5"> PlayDialog </span>
                            <span className="bg-primary rounded-md mx-1 py-0.5 px-1.5"> Play3.0 </span>
                          </span>
                        )}
                        {!row.isZeroShot && (
                          <span className="ml-2 py-0.5 px-1.5 rounded-md font-semibold text-xs bg-success text-neutral-950">
                            PlayHT1.0
                          </span>
                        )}
                      </span>
                    </div>
                    <dl className="font-normal tablet:hidden">
                      <dd className="truncate">
                        <CloningVoiceStatus row={row} />
                      </dd>
                    </dl>
                    <dl className="font-normal tablet:hidden">
                      <dd className="truncate">
                        <CloningVoiceOperations row={row} />
                      </dd>
                    </dl>
                  </div>
                </td>
                <td className="hidden px-6 py-3 tablet:table-cell">
                  <div>
                    <span>
                      <CloningVoiceStatus row={row} />
                    </span>
                  </div>
                </td>
                <td className="hidden px-6 py-3 pr-0.5 tablet:table-cell">
                  <CloningVoiceOperations row={row} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-8">
        <Pagination totalRows={totalRows} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
}

function CloningVoiceStatus({ row }: { row: VCRequestOrVoice }) {
  if (row.isVoice || row.status === 'complete') {
    return (
      <div className="flex items-center gap-2">
        <CheckCircleIcon className="h-5" />
        <div>Cloning completed</div>
      </div>
    );
  } else if (row.status === 'failed') {
    return (
      <WithTooltip
        tooltipChildren={
          row.error ? (
            <div className="flex gap-1 items-center p-2 text-error rounded-md shadow-lg">
              <InformationCircleIcon className="h-5" />
              <span>{getFriendlyErrorMessage(row.error)}</span>
            </div>
          ) : (
            <div />
          )
        }
      >
        <div className="flex text-error gap-2 items-center">
          <ExclamationCircleIcon className="h-6" />
          <div>Cloning failed</div>
        </div>
      </WithTooltip>
    );
  } else {
    return (
      <div className="flex items-center gap-2 text-success">
        <ExclamationCircleIcon className="h-6" />
        <div>Cloning in progress</div>
      </div>
    );
  }
}

function getFriendlyErrorMessage(error: string): string {
  if (typeof error !== 'string' || error.length === 0) {
    return 'We encountered an unidentified error. Please retry, or reach out to our support team for help.';
  }

  if (error.includes('Insufficient data')) {
    return 'Your data seems insufficient. Please provide more clear audio. Note: We discard unclear audio.';
  }
  if (error.includes('list index out of range')) {
    return "Oops! There's an issue with the data processing. Double-check your input and try again.";
  }
  if (error.includes('integer division or modulo by zero')) {
    return 'Training stopped due to data inconsistency. Kindly review your data and retry.';
  }
  if (error.includes('ffmpeg')) {
    return "There's a problem with the audio format. Make sure you're using one of our supported formats and give it another go.";
  }
  if (error.includes('Processing error: ERROR')) {
    return 'We hit a snag while processing. Double-check your input for any irregularities and try again.';
  }
  if (error.includes('Processing error: WARNING')) {
    return 'We came across a warning during processing. Please review your data and consider retrying if necessary.';
  }
  if (error.includes('Training error: Training returned 1, last logs')) {
    return 'Uh-oh! We faced an error during training. Please retry, or contact our support team if the issue persists.';
  }
  if (error.includes('Processing error: Waterfowl returned 1, last lines')) {
    return 'A system hiccup occurred while processing. We apologize for the inconvenience. Please retry or contact us for assistance.';
  }
  if (error.includes("Processing error: 'duration'")) {
    return 'There seems to be an issue with the duration of your input. Verify and try once more.';
  }
  if (error.includes('Processing error: Traceback (most recent call last)')) {
    return 'A system error popped up. We apologize for the trouble. Please try again or get in touch with our support team.';
  }

  // General catch-all message for unhandled errors
  return 'An unexpected error cropped up. Please give it another shot, or reach out to our support if the problem persists.';
}

function CloningVoiceOperations({ row }: { row: VCRequestOrVoice }) {
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const { createAndOpenFile, isLoading } = useCreateAndOpenFile();
  const model = row.isZeroShot ? 'PlayDialog' : 'PlayHT1.0';
  return (
    <>
      <VCShareModal voice={row} visible={shareModalIsOpen} setVisible={setShareModalIsOpen} />
      <DeleteVoiceCloneModal voiceId={row.id} visible={deleteModalIsOpen} setVisible={setDeleteModalIsOpen} />
      <div className="flex justify-end gap-3 max-tablet:justify-center max-tablet:[&>*]:flex-grow">
        <Button
          size="small"
          disabled={!row.isVoice}
          onClick={() => createAndOpenFile({ model, defaultVoice: row.id })}
          loading={isLoading}
        >
          <CursorArrowRaysIcon className="w-4" /> Use
        </Button>
        <Button size="small" disabled={!row.isVoice} onClick={() => setShareModalIsOpen(true)}>
          <ShareIcon className="w-4" /> Share
        </Button>
        <Button size="small" disabled={!row.isVoice} onClick={() => setDeleteModalIsOpen(true)}>
          <TrashIcon className="w-4" /> Delete
        </Button>
      </div>
    </>
  );
}
