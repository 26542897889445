import { z } from 'zod';
import { Version, VersionSchema } from './shared';
import { audioOptionsSchema } from './audio';
import { AudioURLSchema, KettleAudioSampleIdSchema } from './shared';
import { KettleAudioSampleSchema } from './sample';
import { v4 as uuidv4 } from 'uuid';

export const KettleFrameIdSchema = z.string().brand('__FrameId');
export type KettleFrameId = z.infer<typeof KettleFrameIdSchema>;

export const KettleFrameAudioOptionsSchema = audioOptionsSchema.omit({ text: true });
export type KettleFrameAudioOptions = z.infer<typeof KettleFrameAudioOptionsSchema>;

export const KettleFrameSchema = z.object({
  version: z.literal('2'),
  _version: VersionSchema,
  frameId: KettleFrameIdSchema,
  options: KettleFrameAudioOptionsSchema,
  samples: z.array(KettleAudioSampleSchema),
  selectedAudioSampleId: KettleAudioSampleIdSchema.nullable(),
  selectedSample: AudioURLSchema.nullable(),
  delay: z.number().nullable(),
});
export type KettleFrame = z.infer<typeof KettleFrameSchema>;

export const FrameIdsAndOptionsSchema = z
  .function()
  .args(
    z.array(
      z.object({
        frameId: KettleFrameIdSchema,
        options: KettleFrameAudioOptionsSchema,
      })
    )
  )
  .returns(z.promise(z.void()));
export type FrameIdsAndOptions = z.infer<typeof FrameIdsAndOptionsSchema>;

export const increaseFrameVersion = (version: Version | null) => ({
  seq: (version?.seq ?? 0) + 1,
  uuid: uuidv4(),
});

export const zeroFrameVersion = () => increaseFrameVersion(null);
