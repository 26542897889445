import { firestoreDB } from '../../../../utils/firebase.ts';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  limit,
  updateDoc,
  QuerySnapshot,
  onSnapshot,
  DocumentData,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import { KettleFileId, kettleFileIdSchema } from '../../../../domain/kettle/types/shared.ts';
import { FIRESTORE_COLLECTIONS } from '../../../../constants/firestore.ts';
import { UserId } from '../../../../domain/auth/User.ts';
import { v4 as uuid } from 'uuid';
import { KettleFileMetadata } from '../../../../domain/kettle/types/file.ts';

export const KettleMetadataRepository = {
  async getFileMetadata(fileId: KettleFileId): Promise<KettleFileMetadata | null> {
    const document = await getDoc(doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES_METADATA, fileId));
    return document.exists() ? (document.data() as KettleFileMetadata) : null;
  },
  async findFilesByUserId(userId: UserId): Promise<KettleFileMetadata[]> {
    const snapshot = await getDocs(
      query(
        collection(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES_METADATA),
        where('userId', '==', userId),
        limit(1000)
      )
    );
    return snapshot.docs.map((doc) => doc.data() as KettleFileMetadata);
  },
  async updateFileMetadata(fileId: KettleFileId, metadata: Partial<KettleFileMetadata>) {
    await updateDoc(doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES_METADATA, fileId), metadata);
  },
  listenUserFilesMetadata(userId: UserId, onChange: (snapshot: QuerySnapshot<DocumentData>) => void) {
    const filesQuery = query(
      collection(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES_METADATA),
      where('userId', '==', userId),
      limit(1000)
    );
    return onSnapshot(filesQuery, onChange);
  },
  // TODO: update production db rule to allow the user to create a new file metadata if it's empty
  //       allow create: if !exists(/databases/$(database)/documents/studio-files-metadata/$(documentId));
  // 			allow delete: if resource.data.userId == request.auth.uid;
  async createNewFileMetadata(metadata: Omit<KettleFileMetadata, 'id'>): Promise<KettleFileMetadata> {
    const newFileId = kettleFileIdSchema.parse(uuid());
    const newFileRef = doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES_METADATA, newFileId);
    const newFileMetadata = {
      ...metadata,
      id: newFileId,
    };
    await setDoc(newFileRef, newFileMetadata);
    return newFileMetadata;
  },
  async deleteFileMetadata(fileId: KettleFileId) {
    await deleteDoc(doc(firestoreDB, FIRESTORE_COLLECTIONS.KETTLE_FILES_METADATA, fileId));
  },
};
