import { type Stripe, StripeCardElement } from '@stripe/stripe-js';
import StripeForm from './StripeForm';
import { stripeService } from '../../infra/payment/stripeService.ts';
import { User } from '../../domain/auth/User.ts';

export type TPlan = {
  priceId: string;
  price: number;
  quantity?: number;
  add_ons?: boolean;
};

export type PaymentViewRequestProps = {
  plan: TPlan;
  coupon?: string;
};

export type PaymentViewUIProps = {
  onSuccess: () => void;
  payButtonLabel?: string;
};

export type PaymentViewProps = PaymentViewUIProps & PaymentViewRequestProps;

export function PaymentView({ onSuccess, payButtonLabel, plan, coupon }: PaymentViewProps) {
  return (
    <StripeForm
      onSuccess={onSuccess}
      payButtonLabel={payButtonLabel}
      doRequest={(authUser, user, stripe, customerId, card) =>
        doPaymentRequest(user, stripe, customerId, card, plan, coupon)
      }
    />
  );
}

async function doPaymentRequest(
  user: User,
  stripe: Stripe,
  customerId: string,
  card: StripeCardElement,
  plan: TPlan,
  coupon: string | undefined
) {
  await stripeService.subscribeCustomerToPlan({
    stripe,
    userId: user.id,
    userName: user.name ?? '',
    customerId,
    planId: plan.priceId,
    queryQty: plan.quantity ?? 1,
    card,
    coupon,
  });
}
