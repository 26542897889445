import { useEffect, useState } from 'react';
import { getFirestore, doc, onSnapshot, DocumentData, FirestoreError } from 'firebase/firestore';

export const useFirestoreListener = <T, S = DocumentData>(
  path: string,
  options: {
    transform?: (s: S | undefined) => T;
    initialState?: T;
    onError?: (error: FirestoreError) => void;
    firestoreInstance?: ReturnType<typeof getFirestore>;
    isOnHold?: boolean;
  } = {}
) => {
  const [firestoreDocLoadStatus, setFirestoreDocLoadStatus] = useState<'CONNECTING' | 'LISTENING' | 'ERROR'>(
    'CONNECTING'
  );
  const [firestoreDocValue, setFirestoreDocValue] = useState(options.initialState ?? null);

  useEffect(() => {
    if (options.isOnHold) {
      return;
    }

    const db = options.firestoreInstance || getFirestore();
    const docRef = doc(db, path);

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        const data = snapshot.data() as S | undefined;
        const transformedData = options.transform ? options.transform(data) : (data as unknown as T);
        setFirestoreDocValue(transformedData as NonNullable<T> | null);
        setFirestoreDocLoadStatus('LISTENING');
      },
      (error) => {
        console.error(`[useFirestoreListener] Error while reading "${path}":`, error);
        setFirestoreDocLoadStatus('ERROR');
        options.onError?.(error);
      }
    );

    return () => unsubscribe();

    // We don't want to add the whole `options` as dependency array, because it would recreate the listener
    // pretty much every time the component re-renders, unless the client passes a memoized function in the
    // `transform` field, which would make the API a lot less convenient.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, options.firestoreInstance, options.isOnHold]);

  return [firestoreDocValue, firestoreDocLoadStatus] as const;
};

// The FirestoreObject type is not needed for Firestore as it handles null values differently
// and doesn't have the same issues as Realtime Database. However, you can keep it if needed.
