import { User } from '../../../domain/auth/User';
import { User as FirebaseUser } from '@firebase/auth';
import { getActivePackage } from '../../../utils/plans.helpers';

export function generateUserData(user: FirebaseUser | null, userInfo: User | null) {
  return {
    userId: user?.uid || userInfo?.id || 'unknown',
    is_pro: userInfo?.is_pro ?? false,
    plan: userInfo ? getActivePackage(userInfo)?.id ?? 'free' : 'free',
    date: new Date().toJSON(),
    userEmail: user?.email || userInfo?.email || 'unknown',
  };
}
