import { useEffect, useRef, useState } from 'react';
import { KettleFileId } from '../../../domain/kettle/types/shared';
import { UserId } from '../../../domain/auth/User';
import { migrateFileService } from '../db/migrateFileService.ts';

export const MIGRATION_STATUS = {
  LOADING: 'LOADING',
  PROJECT_NOT_FOUND: 'PROJECT_NOT_FOUND',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const useMigrateKettleFile = (fileId?: KettleFileId, userId?: UserId) => {
  const [migrationStatus, setMigrationStatus] = useState(MIGRATION_STATUS.LOADING);
  const migrationStarted = useRef(false);

  useEffect(() => {
    if (!fileId || !userId) return;

    const existingFileId = fileId;
    const existingUserId = userId;

    const executeMigration = () => {
      migrationStarted.current = true;
      migrateFileService(existingFileId, existingUserId).then((migrationResult) => {
        switch (migrationResult) {
          case 'FILE_EXISTS_ON_FIRESTORE':
            setMigrationStatus(MIGRATION_STATUS.SUCCESS);
            break;
          case 'FILE_DOESNT_EXIST':
            setMigrationStatus(MIGRATION_STATUS.PROJECT_NOT_FOUND);
            break;
          case 'ERROR_WHILE_MIGRATING':
            setMigrationStatus(MIGRATION_STATUS.ERROR);
            break;
        }
      });
    };

    if (!migrationStarted.current) {
      executeMigration();
    }
  }, [fileId, userId]);

  return { migrationStatus };
};
