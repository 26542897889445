import { Modal } from './Modal.tsx';
import { useQueryParam } from '../hooks/router.hooks.ts';
import { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Button } from './Button.tsx';

export function PaymentSuccesfulModal() {
  const [open, setOpen] = useState(false);
  const queryParam = useQueryParam('paymentSuccessful');

  useEffect(() => {
    if (queryParam === 'true') {
      setOpen(true);
      // remove the query param and reload the page after 1 second
      setTimeout(() => {
        window.history.replaceState({}, '', window.location.pathname);
        window.location.reload();
      }, 1000);
    }
  }, [queryParam]);

  return (
    <Modal maxWidth="460px" open={open} setOpen={setOpen}>
      <div className="flex flex-col text-center">
        <div className="flex flex-col items-center justify-center p-12 pb-24 max-tablet:p-4">
          <CheckIcon className="mb-6 h-16" />
          <h1 className="mb-2 text-xl font-semibold">Payment Successful</h1>
          <p className="text-xs">
            Please check your email for the receipt. If you need any help, please reach out to us at{' '}
            <a href="mailto:support@play.ht">support@play.ht</a>
          </p>
        </div>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </div>
    </Modal>
  );
}
