import { useUser } from '../contexts/user.context.tsx';
import { useMemo } from 'react';
import {
  CloningRequest,
  completeStatus,
  fetchCloningRequests,
  getMyClonedVoices,
  processedStatus,
  processingStatus,
  VCRequestOrVoice,
  Voice,
} from '../utils/VC.helpers.ts';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../contexts/auth.context.tsx';
import {
  CloneVoiceParams,
  createVoiceClone,
  createZeroShotClone,
  deleteClonedVoice,
  generateVoiceCloneFiles,
  GuideClipT,
  sendVCRequest,
  VCRequestT,
} from '../API/voiceCloning.requests.ts';
import { AxiosError } from 'axios';
import { Gender } from '../pages/KettlePage/hooks/useVoices.ts';
import { VoiceId } from '../__playrepo/play-voice-domain/src/Voice.ts';
import { NOTIFICATION_TYPE_ERROR, useNotifications } from '../__playrepo/ui-components/src/NotificationSnackbar.tsx';
import { UserId } from '../domain/auth/User.ts';

export const VOICE_CLONING_QUERY_KEY = 'voiceCloning';
export const CLONED_VOICES_QUERY_KEY = 'clonedVoices';
export const VOICE_CLONING_REQUESTS_QUERY_KEY = 'voiceCloningRequests';

export function useClonedVoicesAndRequests() {
  const { user } = useUser();
  let userId = user?.id;

  if (user?.isTeamMember && user.teamAdmins && user.teamAdmins.length > 0) {
    userId = user.teamAdmins[0] as UserId;
  }

  const {
    isFetching: isFetchingClonedVoices,
    data: clonedVoicesResponse,
    isFetchedAfterMount: isClonedVoicesFetchedAfterMount,
  } = useQuery({
    queryKey: [VOICE_CLONING_QUERY_KEY, CLONED_VOICES_QUERY_KEY, userId],
    queryFn: async () => (userId ? await getMyClonedVoices(userId) : []),
  });

  const {
    isFetching: isFetchingVoiceCloningRequests,
    data: voiceCloningRequestsResponse,
    isFetchedAfterMount: isVoiceCloningRequestsFetchedAfterMount,
  } = useQuery({
    queryKey: [VOICE_CLONING_QUERY_KEY, VOICE_CLONING_REQUESTS_QUERY_KEY, userId],
    queryFn: async () => (userId ? await fetchCloningRequests(userId) : []),
  });

  const clonedVoices = useMemo(() => clonedVoicesResponse ?? [], [clonedVoicesResponse]);
  const voiceCloningRequests = useMemo(() => voiceCloningRequestsResponse ?? [], [voiceCloningRequestsResponse]);
  const isFetching = useMemo(
    () => isFetchingClonedVoices || isFetchingVoiceCloningRequests,
    [isFetchingClonedVoices, isFetchingVoiceCloningRequests]
  );
  const isFetchedAfterMount = useMemo(
    () => isClonedVoicesFetchedAfterMount && isVoiceCloningRequestsFetchedAfterMount,
    [isClonedVoicesFetchedAfterMount, isVoiceCloningRequestsFetchedAfterMount]
  );

  const voicesByRequests = useMemo(() => {
    const voicesMappedToRequests: Record<string, Voice[]> = {};
    clonedVoices.forEach((voice: Voice) => {
      const requestId = voice.requestId ?? 'UNKNOWN';
      voicesMappedToRequests[requestId] = voicesMappedToRequests[requestId]
        ? [...voicesMappedToRequests[requestId], voice]
        : [voice];
    });
    return voicesMappedToRequests;
  }, [clonedVoices]);

  const vcRequestsInFlight = useMemo(
    () =>
      voiceCloningRequests.filter(
        (cloningRequest) => cloningRequest.status === processingStatus || cloningRequest.status === processedStatus
      ),
    [voiceCloningRequests]
  );

  const vcRequestsAndVoices: VCRequestOrVoice[] = useMemo((): VCRequestOrVoice[] => {
    const requestsWithVoices = voiceCloningRequests.map((request: CloningRequest) => ({
      request,
      voices: voicesByRequests[request.id],
    }));
    const _requestsAndVoices = requestsWithVoices.reduce<VCRequestOrVoice[]>((acc = [], { request, voices }) => {
      if (request.status === completeStatus && voices) {
        voices.forEach((voice: Voice) => {
          acc.push({ ...voice, isVoice: true });
        });
      } else if (request.status !== completeStatus) {
        acc.push({ ...request, isVoice: false });
      }
      return acc;
    }, []);

    if (voicesByRequests['UNKNOWN']?.length > 0) {
      voicesByRequests['UNKNOWN'].forEach((voice: Voice) => {
        _requestsAndVoices.unshift({ ...voice, isVoice: true });
      });
    }

    return _requestsAndVoices;
  }, [voiceCloningRequests, voicesByRequests]);

  return useMemo(
    () => ({ isFetching, isFetchedAfterMount, clonedVoices, vcRequestsInFlight, vcRequestsAndVoices }),
    [isFetching, isFetchedAfterMount, clonedVoices, vcRequestsInFlight, vcRequestsAndVoices]
  );
}

// TODO: Remove this (used by legacy)
export function useCloneInstantVoice({
  onSuccess = () => undefined,
  onSettled = () => undefined,
}: {
  onSuccess?: (voiceId: string) => Promise<void> | void;
  onSettled?: () => Promise<void> | void;
}) {
  const queryClient = useQueryClient();
  const { setNotification } = useNotifications();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async ({
      audioUrl,
      speakerName,
      gender,
      parentVoiceId,
    }: {
      audioUrl: string;
      speakerName: string;
      gender?: Gender;
      parentVoiceId?: string;
    }) => {
      const token = (await currentUser?.getIdToken()) ?? '';
      return await createZeroShotClone(
        { voiceName: speakerName, sampleFileURL: audioUrl, gender, parentVoiceId },
        token
      );
    },
    onSuccess: async (voiceId) => {
      queryClient.invalidateQueries({ queryKey: [VOICE_CLONING_QUERY_KEY] });
      setNotification({ status: 'success', message: 'Cloning request created successfully' });
      await onSuccess(voiceId);
    },
    onError: (error: unknown) => {
      let message = 'Something went wrong';
      if (error instanceof AxiosError && error.response?.data?.message) {
        message = error.response.data.message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setNotification({ message, status: NOTIFICATION_TYPE_ERROR });
    },
    onSettled,
  });
}

export function useCloneHFVoice(onSettled: () => void = () => ({})) {
  const queryClient = useQueryClient();
  const { setNotification } = useNotifications();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async ({
      audioUrls,
      speakerName,
      hasMultipleSpeakers,
      clippings,
      ar_epochs,
      diff_epochs,
    }: {
      audioUrls: string[];
      speakerName: string;
      hasMultipleSpeakers: boolean;
      clippings?: GuideClipT[];
      ar_epochs: number;
      diff_epochs: number;
    }) => {
      if (!currentUser) return;
      const token = await currentUser.getIdToken();
      const payload: VCRequestT = {
        token,
        name: speakerName,
        training_data: audioUrls,
        // consentAudioUrl, // Not used for now
        ar_epochs,
        diff_epochs,
        single_speaker: true,
      };
      if (hasMultipleSpeakers) {
        payload['single_speaker'] = false;
      }
      if (clippings && clippings.length > 0) {
        payload['guide_clips'] = clippings.map(({ index, ...rest }) => ({
          data_index: index,
          ...rest,
        }));
      }
      await sendVCRequest(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [VOICE_CLONING_QUERY_KEY] });
      setNotification({ status: 'success', message: 'Cloning request created successfully' });
    },
    onError: (error: unknown) => {
      let message = 'Something went wrong';
      if (error instanceof AxiosError && error.response?.data?.message) {
        message = error.response.data.message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setNotification({ message, status: NOTIFICATION_TYPE_ERROR });
    },
    onSettled,
  });
}

export function useDeleteClonedVoice(onSettled: () => void = () => ({})) {
  const queryClient = useQueryClient();
  const { setNotification } = useNotifications();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async (voiceId: string) => {
      const token = (await currentUser?.getIdToken()) ?? '';
      await deleteClonedVoice(token, voiceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [VOICE_CLONING_QUERY_KEY] });
      setNotification({ status: 'success', message: 'Voice deleted successfully' });
    },
    onError: (error: unknown) => {
      let message = 'Something went wrong';
      if (error instanceof AxiosError && error.response?.data?.message) {
        message = error.response.data.message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setNotification({ message, status: NOTIFICATION_TYPE_ERROR });
    },
    onSettled,
  });
}

export function useGenerateVoiceCloneFiles({
  onError = () => undefined,
  onSettled = () => undefined,
}: {
  onError?: (e: unknown) => Promise<void> | void;
  onSettled?: () => Promise<void> | void;
}) {
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async (sampleUrl: string) => {
      const token = (await currentUser?.getIdToken()) ?? '';
      return generateVoiceCloneFiles(sampleUrl, token);
    },
    onError,
    onSettled,
  });
}

export function useCreateVoiceClone({
  onSuccess = () => undefined,
  onError = () => undefined,
  onSettled = () => undefined,
}: {
  onSuccess?: (voiceId: VoiceId) => Promise<void> | void;
  onError?: (e: unknown) => Promise<void> | void;
  onSettled?: () => Promise<void> | void;
}) {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  return useMutation({
    mutationFn: async (params: CloneVoiceParams) => {
      const token = (await currentUser?.getIdToken()) ?? '';
      return createVoiceClone(params, token);
    },
    onError,
    onSettled,
    onSuccess: async (voiceId: VoiceId) => {
      await queryClient.invalidateQueries({ queryKey: [VOICE_CLONING_QUERY_KEY] });
      onSuccess(voiceId);
    },
  });
}

export function useDeleteClonedVoices() {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const { setNotification } = useNotifications();
  return useMutation({
    mutationFn: async (voicesIds: string[]) => {
      const token = (await currentUser?.getIdToken()) ?? '';
      const promises = voicesIds.map((id) => deleteClonedVoice(token, id));
      await Promise.all(promises);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [VOICE_CLONING_QUERY_KEY] });
      setNotification({ status: 'success', message: 'Voices deleted successfully' });
    },
    onError: () => setNotification({ message: 'An error occurred trying to delete the voices', status: 'error' }),
  });
}
