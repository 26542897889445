import { PEREGRINE_CONSTANTS } from '../../../../../../constants/peregrine.ts';
import { KettleAudioSample } from '../../../../../../domain/kettle/types/sample.ts';

/**
 * @typedef {Object} AudioSample
 * @property {string} audioURL
 * @property {string} status
 * @property {string} requestGenerationTime
 * @property {string} [requestCompletionTime]
 * @property {string} title
 * @property {string} preset
 * @property {{ voice: string, speed: string }} options
 */

/**
 * @param {AudioSample} audioSample
 * @returns {AudioSample}
 */
const normalizeAudioSample = (audioSample: KettleAudioSample) => {
  let normalizedAudioSample = audioSample;
  normalizedAudioSample = normalizeRequestGenerationTime(normalizedAudioSample);
  normalizedAudioSample = normalizeTitle(normalizedAudioSample);
  normalizedAudioSample = normalizePreset(normalizedAudioSample);
  normalizedAudioSample = normalizeOptions(normalizedAudioSample);
  return normalizedAudioSample;
};

/**
 * @param {AudioSample} audioSample
 * @returns {AudioSample}
 */
const normalizeTitle = (audioSample: KettleAudioSample) => {
  if (audioSample.title === undefined || audioSample.title === null) return { ...audioSample, title: '' };
  return audioSample;
};

/**
 * @param {AudioSample} audioSample
 * @returns {AudioSample}
 */
const normalizeOptions = (audioSample: KettleAudioSample) => {
  if (audioSample.options && audioSample.options.voice && audioSample.options.speed) return audioSample;
  return {
    ...audioSample,
    options: {
      ...audioSample.options,
      voice: audioSample.options?.voice ?? PEREGRINE_CONSTANTS.DEFAULT_VOICE,
      speed: audioSample.options?.speed ?? PEREGRINE_CONSTANTS.DEFAULT_SPEED,
    },
  };
};

/**
 * @param {AudioSample} audioSample
 * @returns {AudioSample}
 */
const normalizePreset = (audioSample: KettleAudioSample) => {
  if (audioSample.preset) return audioSample;
  return { ...audioSample, preset: PEREGRINE_CONSTANTS.DEFAULT_PRESET };
};

/**
 * @param {AudioSample} audioSample
 * @returns {AudioSample}
 */
const normalizeRequestGenerationTime = (audioSample: KettleAudioSample) => {
  if (audioSample.requestGenerationTime) return audioSample;
  // the field used to be called `timestamp`
  // noinspection JSUnresolvedVariable
  return {
    ...audioSample,
    requestGenerationTime: audioSample.timestamp ?? '2020-01-01T12:00:00.000Z',
    timestamp: null,
  };
};

/**
 * @param {AudioSample} audioSample
 * @param {string} newTitle
 * @returns {AudioSample}
 */
const sampleWithUpdatedTitle = (audioSample: KettleAudioSample, newTitle: string) => {
  const normalized = normalizeAudioSample(audioSample);
  return normalizeTitle({ ...normalized, title: newTitle });
};

export const AudioSampleService = {
  sampleWithUpdatedTitle,
  normalizeAudioSample,
};
