export const KettleLoadingError = ({ message, allowRetry = false }: { message: string; allowRetry: boolean }) => (
  <div className="flex h-screen flex-col justify-center gap-4 text-center">
    <div>{message}</div>
    {allowRetry ? (
      <>
        <div>Click below to try again in a few moments. If the error persists, please contact our support team.</div>
        <div>
          <button
            className="kettle-button-rounded"
            style={{ display: 'inline-block' }}
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      </>
    ) : null}
  </div>
);
