import { UserId } from '../../../domain/auth/User.ts';
import { KettleRepository } from './studio-file/kettle.repository.ts';
import { KettleLegacyRTDBRepository } from './kettleLegacyRTDB.repository.ts';
import { KettleMetadataRepository } from './studio-file-metadata/kettleMetadata.repository.ts';
import { RecentFilesService } from './recent-files/RecentFilesService.ts';
import { PEREGRINE_CONSTANTS } from '../../../constants/peregrine.ts';
import { KettleFileId } from '../../../domain/kettle/types/shared.ts';
import { KettleFileMetadata } from '../../../domain/kettle/types/file.ts';
import {
  AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_LAST_VERSION,
  AUDIO_FRAMES__EDITOR_CONFIG__VERSION_KEY,
  AUDIO_FRAMES__EDITOR_CONFIG_KEY,
  AUDIO_FRAMES__FRAMES_KEY,
} from '../../../domain/kettle/types/constants.ts';
import { reportKettleError } from '../utils/reportKettleIssue';

//  TODO: update production db permissions to allow write access from owner
// "recentFiles": {
//   "$user_id": {
//     ".read": "$user_id === auth.uid",
//     ".write": "$user_id === auth.uid"
//   },
// }
// ALSO ADD FIRESTORE DB PERMISSIONS IN PRODUCTION

// We can add migration logic from kettle versions 1.0.0 to 2.0.0 or 3.0.0 , etc, here. (if needed)

// we can also move the logic for refreshing the project state (removing not used frames, etc) here
// https://github.com/playht/Play-Studio/blob/3b7ameed-12-12-audiframes_firestore_migration/backend/src/services/kettle/kettleProject.service.js#L65
export async function migrateFileService(
  fileId: KettleFileId,
  userId: UserId
): Promise<'FILE_DOESNT_EXIST' | 'FILE_EXISTS_ON_FIRESTORE' | 'ERROR_WHILE_MIGRATING'> {
  try {
    const metadata = await KettleMetadataRepository.getFileMetadata(fileId);
    const file = await KettleRepository.getFile(fileId);

    // File doesn't exist at all
    if (!metadata) {
      return 'FILE_DOESNT_EXIST';
    }

    // File exists and has been migrated in the past
    if (file) {
      updateRecentFilesInBackground(userId, fileId, metadata);
      return 'FILE_EXISTS_ON_FIRESTORE';
    }

    const fileFromRTDB = await KettleLegacyRTDBRepository.getFile(fileId);
    // File (metadata) exists (see above), and file doesn't exist on Firestore but exists on RTDB, so we migrate it
    if (fileFromRTDB) {
      await KettleRepository.createFile(fileFromRTDB);
      updateRecentFilesInBackground(userId, fileId, metadata);
      return 'FILE_EXISTS_ON_FIRESTORE';
    }

    // File (metadata) exists (see above), and file doesn't exist on Firestore or RTDB, so we create it
    await createEmptyKettleFile(fileId, userId);
    updateRecentFilesInBackground(userId, fileId, metadata);
    return 'FILE_EXISTS_ON_FIRESTORE';
  } catch (error) {
    console.error('error migrating project', error);
    return 'ERROR_WHILE_MIGRATING';
  }
}

function createEmptyKettleFile(fileId: KettleFileId, userId: UserId) {
  return KettleRepository.createFile({
    projectId: fileId,
    userId: userId,
    [AUDIO_FRAMES__EDITOR_CONFIG_KEY]: {
      editorPreset: PEREGRINE_CONSTANTS.DEFAULT_PRESET,
      [AUDIO_FRAMES__EDITOR_CONFIG__VERSION_KEY]: AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_LAST_VERSION,
    },
    [AUDIO_FRAMES__FRAMES_KEY]: [],
  });
}

function updateRecentFilesInBackground(userId: UserId, fileId: KettleFileId, metadata: KettleFileMetadata | null) {
  RecentFilesService.updateRecentFiles(userId, fileId, metadata?.title ?? null).catch((e) => {
    reportKettleError(fileId, `Error while updating recent files from migration. File: ${fileId}`, e);
  });
}
