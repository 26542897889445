import { User } from '../../../../domain/auth/User.ts';
import { FileModel } from '../../../../domain/files/File.ts';
import { KettleFileMetadata } from '../../../../domain/kettle/types/file.ts';
import { KettleMetadataRepository } from './kettleMetadata.repository.ts';

export async function createFileMetadataService(
  user: User,
  model: FileModel = 'PlayDialog'
): Promise<KettleFileMetadata> {
  const metadata: Omit<KettleFileMetadata, 'id'> = {
    userId: user.id,
    appId: user.appId,
    isStudio: true,
    isStudioAudio: true,
    model,
    modifiedDate: Date.now(),
  };

  return await KettleMetadataRepository.createNewFileMetadata(metadata);
}
