export const AUDIO_FRAMES__EDITOR_CONFIG_KEY = 'editorConfig';
export const AUDIO_FRAMES__EDITOR_CONFIG__VERSION_KEY = 'version';
export const AUDIO_FRAMES__FRAMES_KEY = 'frames';
export const AUDIO_FRAMES__SERIALIZED_EDITOR_STATE_KEY = 'serializedEditorState';

export const AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_VERSION_PREFIX = 'kettle-';
export const AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_VERSION_1 = `${AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_VERSION_PREFIX}1`;
export const AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_VERSION_2 = `${AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_VERSION_PREFIX}2`;
export const AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_VERSION_3 = `${AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_VERSION_PREFIX}3`;
export const AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_LAST_VERSION = AUDIO_FRAMES__EDITOR_CONFIG__KETTLE_VERSION_3;
