import { AuthProvider } from './contexts/auth.context.tsx';
import { Outlet } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { PaymentSuccesfulModal } from './components/PaymentSuccesfulModal.tsx';
import { ErrorPage } from './pages/Error/ErrorPage.tsx';
import { UserProvider } from './contexts/user.context.tsx';
import { ConfigCatProvider, PollingMode } from 'configcat-react';
import { WelcomeTeamMemberModal } from './components/WelcomeTeamMemberModal.tsx';
import { SentryFatalErrorBoundary } from './infra/errors/SentryErrorBoundary.tsx';
import { errorsTracker } from './infra/errors/errorsTracker.ts';
import { CONFIG } from './config.ts';
import { StripeProvider } from './infra/payment/StripeProvider.tsx';
import { PHProvider } from './infra/analytics/PHProvider.tsx';
import { AnnouncementsJournal } from './components/News/AnnouncementsJournal.tsx';
import { NotificationsProvider } from './__playrepo/ui-components/src/NotificationSnackbar.tsx';
import { VoiceCloningModal } from './components/CreateVoiceCloneWizard/CreateVoiceCloneWizard.tsx';
import { VerifyEmailModal } from './components/VerifyEmailModal.tsx';

const queryClient = errorsTracker.createReactQueryClient();

export const Providers = () => (
  <SentryFatalErrorBoundary fallback={ErrorPage}>
    <PHProvider>
      <ConfigCatProvider
        sdkKey={CONFIG.configCatKey}
        pollingMode={PollingMode.AutoPoll}
        options={{ pollIntervalSeconds: CONFIG.configCatInterval }}
      >
        <QueryClientProvider client={queryClient}>
          <NotificationsProvider>
            <StripeProvider>
              <AuthProvider>
                <UserProvider>
                  <Outlet />
                  <GlobalModals />
                </UserProvider>
              </AuthProvider>
            </StripeProvider>
          </NotificationsProvider>
        </QueryClientProvider>
      </ConfigCatProvider>
    </PHProvider>
  </SentryFatalErrorBoundary>
);

function GlobalModals() {
  return (
    <>
      <AnnouncementsJournal />
      <PaymentSuccesfulModal />
      <WelcomeTeamMemberModal />
      <VoiceCloningModal />
      <VerifyEmailModal />
    </>
  );
}
