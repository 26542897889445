import { FrameIdsAndOptions, KettleFrame } from '../../../../domain/kettle/types/frame.ts';
import { KettleFileId } from '../../../../domain/kettle/types/shared.ts';
import { KettleRepository } from './kettle.repository.ts';

export const createNewEmptyFramesService = async (fileId: KettleFileId, frameIdsAndOptions: FrameIdsAndOptions) => {
  return addFramesToFilesService(fileId, frameIdsAndOptions);
};

const addFramesToFilesService = async (fileId: KettleFileId, frameIdsAndOptions: FrameIdsAndOptions) => {
  const newFrames = (Array.isArray(frameIdsAndOptions) ? frameIdsAndOptions : [frameIdsAndOptions]).map((frame) => ({
    ...frame,
  }));

  return KettleRepository.updateFramesInFile(fileId, (existingFrames: KettleFrame[] | null) => {
    if (existingFrames === null) {
      return newFrames;
    }

    // merge frame objects with the same frameId into one single frame object per frameId
    const framesMap = new Map(existingFrames.map((f) => [f.frameId, f]));
    newFrames.forEach((nf) => {
      framesMap.set(nf.frameId, { ...framesMap.get(nf.frameId), ...nf });
    });
    return [...framesMap.values()];
  });
};
