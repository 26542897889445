import { errorsTracker, SentryErrorLevel } from '../../../infra/errors/errorsTracker';
import { KettleFileId } from '../../../domain/kettle/types/shared.ts';

export const reportKettleError = (projectId: KettleFileId, message: string, error: unknown) =>
  reportKettleIssue(projectId, message, error, 'error');

export const reportKettleInfo = (projectId: KettleFileId, message: string, error: unknown) =>
  reportKettleIssue(projectId, message, error, 'info');

const reportKettleIssue = (projectId: KettleFileId, message: string, error: unknown, level: SentryErrorLevel) =>
  errorsTracker.report(level, error, `Kettle -- ${message}`, { extra: { 'project-id': projectId } });
